import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Image, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuoteStart,
  quotesListStart,
} from "../../store/slices/BeneficiariesSlice";
import { createTransactionStart } from "../../store/slices/BeneficiariesSlice";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { ButtonLoader } from "../Helper/Loader";
import { useNavigate } from "react-router-dom";

const MoneyTransferModal = (props) => {
  const t = useTranslation("quote.quote_transfer");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createTransaction = useSelector(
    (state) => state.beneficiaries.createTransaction
  );
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().max(255, t("Invalid")),
  });

  const handleSubmit = (values) => {
    dispatch(
      createTransactionStart({
        ...values,
        quote_id: props.show.id,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(createTransaction.data).length > 0 &&
      !createTransaction.loading
    ) {
      navigate("/beneficiary-transactions");
    }
    setSkipRender(false);
  }, [createTransaction]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="card-sendmoney-modal"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-card-title">
          <h3 className="change-password-heading">{t("transfer_money")}</h3>
        </div>
        <div className="quote-info-card mb-3">
          <div className="quote-card">
            <h5>Delivery Time</h5>
            <p>
              Delivery:
              <span>
                {props.show.quote.delivery_time}
              </span>
            </p>
          </div>
          <div className="card-part"></div>
          <div className="quote-card">
            <h5>Charged Amount</h5>
            <p>
              {props.show.quote.charged_amount.currency}:
              <span>
                {props.show.quote.charged_amount.amount}
              </span>
            </p>
          </div>
          <div className="card-part"></div>
          <div className="quote-card">
            <h5>Converted Amount</h5>
            <p>
              {props.show.quote.converted_amount.currency}:
              <span>
                {props.show.quote.converted_amount.amount}
              </span>
            </p>
          </div>
          <div className="card-part"></div>
          <div className="quote-card">
            <h5>Recipient</h5>
            <p>
              {" "}
              {
                props.show.quote.recipient_amount.currency
              } :{" "}
              <span>
                {props.show.quote.recipient_amount.amount}
              </span>{" "}
            </p>
          </div>
        </div>
        <Formik
          initialValues={{
            remarks: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <FORM className="efi-bank-default-form w-100">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      Remarks
                    </Form.Label>
                    <Field
                      as="textarea"
                      rows="4"
                      className="form-control"
                      placeholder="Enter Remarks"
                      name="remarks"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="remarks"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="onboarding-auth-footer">
                <Button
                  className="cancel-button"
                  onClick={props.onHide}
                  disabled={createTransaction.buttonDisable}
                >
                  Cancel
                </Button>
                <Button
                  className="efi-primary-btn w-100"
                  type="submit"
                  disabled={createTransaction.buttonDisable}
                >
                  {createTransaction.buttonDisable ? (
                    <ButtonLoader />
                  ) : (
                    "Send Money"
                  )}
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default MoneyTransferModal;
