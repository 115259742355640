import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Nav,
  Form,
  InputGroup
} from "react-bootstrap";
import WalletTransaction from "./WalletTransaction";
import WithDrawModal from "./WithDrawModal";
import WithDrawHistory from "./WithDrawHistory";

const WalletIndex = (props) => {

  const [withDraw, setWithDraw] = useState(false);

  const closeWithDrawModal = () => {
    setWithDraw(false);
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="wallet-sec">
          <Row>
            <Col md={12}>
              <div className="wallet-header-right-sec">
                <Button
                  type="button"
                  className="export-data-btn"
                  onClick={() => setWithDraw(true)}
                >
                  Withdraw Amount
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="resp-mrg-btm-xs">
              <div className="wallet-card">
                <div className="wallet-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" enable-background="new 0 0 512 512" viewBox="0 0 32 32"><path fill="#000" d="M4.856 9.003l16.408-4.396a3 3 0 013.674 2.121L25.547 9H27a3 3 0 013 3v13a3 3 0 01-3 3H5a3 3 0 01-3-3V12a3.001 3.001 0 012.856-2.997zM28 15v-3a1 1 0 00-1-1H4.986A1 1 0 004 12v13a1 1 0 001 1h22a1 1 0 001-1v-3h-7a2 2 0 01-2-2v-3a2 2 0 012-2zm-7 2v3h7v-3zm2.477-8l-.47-1.754a1 1 0 00-1.225-.707L12.596 9z" data-original="#000000"></path></svg>
                </div>
                <div className="wallet-info-sec">
                  <h4 className="title">$150.00</h4>
                  <p className="desc">Total</p>
                </div>
              </div>
            </Col>
            <Col md={4} className="resp-mrg-btm-xs">
              <div className="wallet-card">
                <div className="wallet-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" enable-background="new 0 0 512 512" viewBox="0 0 32 32"><path fill="#000" d="M4.856 9.003l16.408-4.396a3 3 0 013.674 2.121L25.547 9H27a3 3 0 013 3v13a3 3 0 01-3 3H5a3 3 0 01-3-3V12a3.001 3.001 0 012.856-2.997zM28 15v-3a1 1 0 00-1-1H4.986A1 1 0 004 12v13a1 1 0 001 1h22a1 1 0 001-1v-3h-7a2 2 0 01-2-2v-3a2 2 0 012-2zm-7 2v3h7v-3zm2.477-8l-.47-1.754a1 1 0 00-1.225-.707L12.596 9z" data-original="#000000"></path></svg>
                </div>
                <div className="wallet-info-sec">
                  <h4 className="title">$140.00</h4>
                  <p className="desc">Remaining</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="wallet-card">
                <div className="wallet-icon-sec">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" enable-background="new 0 0 512 512" viewBox="0 0 32 32"><path fill="#000" d="M4.856 9.003l16.408-4.396a3 3 0 013.674 2.121L25.547 9H27a3 3 0 013 3v13a3 3 0 01-3 3H5a3 3 0 01-3-3V12a3.001 3.001 0 012.856-2.997zM28 15v-3a1 1 0 00-1-1H4.986A1 1 0 004 12v13a1 1 0 001 1h22a1 1 0 001-1v-3h-7a2 2 0 01-2-2v-3a2 2 0 012-2zm-7 2v3h7v-3zm2.477-8l-.47-1.754a1 1 0 00-1.225-.707L12.596 9z" data-original="#000000"></path></svg>
                </div>
                <div className="wallet-info-sec">
                  <h4 className="title">$10.00</h4>
                  <p className="desc">Used</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="wallet-tab-sec">
          <Tab.Container id="left-tabs-example" defaultActiveKey="wallet-transaction">
            <div className="wallet-tab-header-sec">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="wallet-transaction">
                    Transaction
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="withdraw-history">
                    Withdraw History
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="wallet-search-sec">
                <div className="payment-search-bar">
                  <InputGroup className="mb-0">
                    <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#8f9899"
                          d="M23.707 22.293l-5.969-5.969a10.016 10.016 0 10-1.414 1.414l5.969 5.969a1 1 0 001.414-1.414zM10 18a8 8 0 118-8 8.009 8.009 0 01-8 8z"
                          data-original="#000000"
                        ></path>
                      </svg>
                    </InputGroup.Text>
                    <Form.Control type="search" placeholder="search" aria-label="search" aria-describedby="search-addon" />
                  </InputGroup>
                </div>
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="wallet-transaction">
                <WalletTransaction />
              </Tab.Pane>
              <Tab.Pane eventKey="withdraw-history">
                <WithDrawHistory />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <WithDrawModal
        withDraw={withDraw}
        closeWithDrawModal={closeWithDrawModal}
        setWithDraw={setWithDraw}
      />
    </>
  );
};

export default WalletIndex;
