import React, { useState, useEffect } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import OtpInput from "react-otp-input";
import './modal.css';

const TwoSteoAuthModal = (props) => {
  const [otp, setOtp] = useState('');
  // state = {
  //   value: '',
  //   copied: false,
  // };
  const [state, setState] = useState('');
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="efi-bank-step-authentication-wrapped">
            <div className="efi-bank-step-authentication-head">
              <h3> Two-Step Authentication </h3>
              <div className="qr-avater-framr">
                <Image src={window.location.origin + "/img/feature/qr-code.png"} className="qr-avater" />
              </div>
            </div>
            <div className="efi-bank-step-authentication-content">
              <ul className="list-unstyled">
                <li>
                  <div className="efi-bank-step-authentic-card">
                    <div className="efi-bank-step-count">
                      1
                    </div>
                    <div className="efi-bank-step-info">
                      <p>Scan the QR code using authentication application on from your Device or Enter the following Code:</p>
                      <h5><span> YRTWEITWEOIR56897452HJYEIBKLNE23456   </span> <CopyToClipboard
                        text="demo">
                        <Button className="copy-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 699.428 699.428"
                          >
                            <path
                              fill="#7b35f8"
                              d="M502.714 0H240.428C194.178 0 153 42.425 153 87.429l-25.267.59c-46.228 0-84.019 41.834-84.019 86.838V612c0 45.004 41.179 87.428 87.429 87.428H459c46.249 0 87.428-42.424 87.428-87.428h21.857c46.25 0 87.429-42.424 87.429-87.428v-349.19L502.714 0zM459 655.715H131.143c-22.95 0-43.714-21.441-43.714-43.715V174.857c0-22.272 18.688-42.993 41.638-42.993l23.933-.721v393.429C153 569.576 194.178 612 240.428 612h262.286c0 22.273-20.765 43.715-43.714 43.715zm153-131.143c0 22.271-20.765 43.713-43.715 43.713H240.428c-22.95 0-43.714-21.441-43.714-43.713V87.429c0-22.272 20.764-43.714 43.714-43.714H459c-.351 50.337 0 87.975 0 87.975 0 45.419 40.872 86.882 87.428 86.882H612v306zm-65.572-349.715c-23.277 0-43.714-42.293-43.714-64.981V44.348L612 174.857h-65.572zm-43.714 131.537H306c-12.065 0-21.857 9.77-21.857 21.835 0 12.065 9.792 21.835 21.857 21.835h196.714c12.065 0 21.857-9.771 21.857-21.835 0-12.065-9.792-21.835-21.857-21.835zm0 109.176H306c-12.065 0-21.857 9.77-21.857 21.834 0 12.066 9.792 21.836 21.857 21.836h196.714c12.065 0 21.857-9.77 21.857-21.836 0-12.064-9.792-21.834-21.857-21.834z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </Button>
                      </CopyToClipboard> </h5>

                    </div>
                  </div>
                </li>
                <li>
                  <div className="efi-bank-step-authentic-card">
                    <div className="efi-bank-step-count">
                      2
                    </div>
                    <div className="efi-bank-step-info">
                      <p>Enter the 6 figure configuration code shown on application:</p>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        className="custome-otp-input"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="efi-bank-default-form">
              <div className="form-modal-footer">
                <Button className="cancel-button" onClick={props.onHide}>
                  cancel
                </Button>
                <Button className="submit-button">
                Activate
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TwoSteoAuthModal;
