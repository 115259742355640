import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  InputGroup,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import { cardBlockUnblockStart, cardViewStart, physicalCardAssignStart, virtualCardCreateStart } from "../../store/slices/CardSlice";
import { transferMoneyStart } from "../../store/slices/PaymentSlice";

const OtpVerification = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("cards.otp_verification");
  const cardView = useSelector((state) => state.card.cardView);
  const [otp, setOtp] = useState('');
  const cardBlockUnblock = useSelector((state) => state.card.cardBlockUnblock);
  const [skipRender, setSkipRender] = useState(true);
  const transferMoney = useSelector((state) => state.payment.transferMoney);

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        props.showOtpVerify.action == 4 ? 
        dispatch(cardViewStart({
          verification_code: otp,
          m_card_unique_id: props.showOtpVerify.unique_id,
        })) : props.showOtpVerify.action == 5 ? dispatch(transferMoneyStart({
          ...props.showOtpVerify,
          verification_code: otp,
        })) :  dispatch(cardBlockUnblockStart({
          ...props.showOtpVerify,
          verification_code: otp,
          m_card_unique_id: props.showOtpVerify.unique_id,
          blockAction:
            props.showOtpVerify.action == 1
              ? "temporaryBlock"
              : props.showOtpVerify.action == 2
              ? "permanentBlock"
              : "unblock",
        }))
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cardBlockUnblock.loading &&
      Object.keys(cardBlockUnblock.data).length > 0
    ) {
      props.closeOtpVerifyModal();
    }
    setSkipRender(false);
  }, [cardBlockUnblock]);

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.showOtpVerify}
        onHide={props.closeOtpVerifyModal}
        className="otp-validation-model"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sign-up-verification">
            <div className="otp-frame-title mt-3">
              <Image src={window.location.origin +"/img/feature/otp-icon.svg"}/>
              <h2> Input 2FA code from virtual device</h2>
              <p>Please input the code shown on your virtual device for{" "}
              {props.showOtpVerify.action == 4 ? "card details" : props.showOtpVerify.action == 5 ? "money transfer" : props.showOtpVerify.action == 3 ? "unblocking card" : "blocking card" }
              </p>
            </div>
            <Formik
              initialValues={{
                otp: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FORM className="auth-form-sec">
                  <div className="sign-up-verification-input verification-item">
                    <OtpInput
                      className="otp-box"
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <div className="efi-bank-default-form">
                    <div className="verification-btn-sec">
                      <div className="form-modal-footer">
                      <Button className="submit-button" type="submit" disabled={cardView.buttonDisable || cardBlockUnblock.buttonDisable || transferMoney.buttonDisable}>
                        {(cardView.buttonDisable || cardBlockUnblock.buttonDisable || transferMoney.buttonDisable) ? (
                          <ButtonLoader />
                        ) : (
                          t("validate")
                        )}</Button>
                      </div>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;
