import React from "react";
import { Table, Modal} from "react-bootstrap";

const ViewBeneficiary = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Beneficiary Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>Albert</td>
              </tr>
              <tr>
                <td>Account Number</td>
                <td>12345678972568923</td>
              </tr>
              <tr>
                <td>Account Type</td>
                <td><div className='green-badge'>Saving</div></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewBeneficiary;
