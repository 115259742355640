import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  businessInfoUpdateFailure,
  businessInfoUpdateSuccess,
  businessUserStoreFailure,
  businessUserStoreSuccess,
  businessUserListFailure,
  businessUserListSuccess,
  businessUserShowFailure,
  businessUserShowSuccess,
  updateBusinessDocumentSuccess,
  updateBusinessDocumentFailure,
  businessDocumentsInfoSuccess,
  businessDocumentsInfoFailure,
  businessDocumentsStatusSuccess,
  businessDocumentsStatusFailure,
  personalDocumentsStatusSuccess,
  personalDocumentsStatusFailure,
} from "../slices/BusinessSlice";
import { profileSuccess } from "../slices/AdminSlice";

function* businessInfoUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_business",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessInfoUpdateSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessInfoUpdateFailure,
  });
}

function* businessUserStoreAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users/store",
    payload: action.payload,
    successNotify: true,
    success: businessUserStoreSuccess,
    failure: businessUserStoreFailure,
  });
}

function* businessUserListAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users",
    payload: action.payload,
    successNotify: false,
    success: businessUserListSuccess,
    failure: businessUserListFailure,
  });
}

function* businessUserShowAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users/show",
    payload: action.payload,
    successNotify: false,
    success: businessUserShowSuccess,
    failure: businessUserShowFailure,
  });
}

function* updateBusinessDocumentAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_business_document",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateBusinessDocumentSuccess(data));
      store.dispatch(businessDocumentsInfoSuccess(data));
    },
    failure: updateBusinessDocumentFailure,
  });
}

function* businessDocumentsInfoAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents",
    payload: action.payload,
    successNotify: false,
    success: businessDocumentsInfoSuccess,
    failure: businessDocumentsInfoFailure,
  });
}

function* businessDocumentsStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents_status",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    errorNotify: false,
    success: (data) => {
      store.dispatch(businessDocumentsStatusSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessDocumentsStatusFailure,
  });
}

function* personalDocumentsStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_status",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    errorNotify: false,
    success: (data) => {
      store.dispatch(personalDocumentsStatusSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: personalDocumentsStatusFailure,
  });
}

export default function* BusinessSaga() {
  yield all([
    yield takeLatest("business/businessInfoUpdateStart", businessInfoUpdateAPI),
    yield takeLatest("business/businessUserStoreStart", businessUserStoreAPI),
    yield takeLatest("business/businessUserListStart", businessUserListAPI),
    yield takeLatest("business/businessUserShowStart", businessUserShowAPI),
    yield takeLatest(
      "business/updateBusinessDocumentStart",
      updateBusinessDocumentAPI
    ),
    yield takeLatest(
      "business/businessDocumentsInfoStart",
      businessDocumentsInfoAPI
    ),
    yield takeLatest(
      "business/businessDocumentsStatusStart",
      businessDocumentsStatusAPI
    ),
    yield takeLatest(
      "business/personalDocumentsStatusStart",
      personalDocumentsStatusAPI
    ),
  ]);
}
