import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
const AccountViewDetails = ({ account }) => {
  const t = useTranslation("account.account_view_details");
  return (
    <>
      <div className="efi-bank-view-wrapped">
        <div className="efi-bank-view-content-frame">
          <div className="efi-bank-view-content-head">
            <div className="efi-bank-head-info">
              <h3>$78,987</h3>
              <p>
                {" "}
                <span>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill=" #549F69"
                    className="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                    viewBox="0 0 24 24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12.01 2.011a3.2 3.2 0 012.113.797l.154.145.698.698a1.2 1.2 0 00.71.341L15.82 4h1a3.2 3.2 0 013.195 3.018l.005.182v1c0 .27.092.533.258.743l.09.1.697.698a3.2 3.2 0 01.147 4.382l-.145.154-.698.698a1.2 1.2 0 00-.341.71l-.008.135v1a3.2 3.2 0 01-3.018 3.195l-.182.005h-1a1.2 1.2 0 00-.743.258l-.1.09-.698.697a3.2 3.2 0 01-4.382.147l-.154-.145-.698-.698a1.2 1.2 0 00-.71-.341L8.2 20.02h-1a3.2 3.2 0 01-3.195-3.018L4 16.82v-1a1.2 1.2 0 00-.258-.743l-.09-.1-.697-.698a3.2 3.2 0 01-.147-4.382l.145-.154.698-.698a1.2 1.2 0 00.341-.71L4 8.2v-1l.005-.182a3.2 3.2 0 013.013-3.013L7.2 4h1a1.2 1.2 0 00.743-.258l.1-.09.698-.697a3.2 3.2 0 012.269-.944zm3.697 7.282a1 1 0 00-1.414 0L11 12.585l-1.293-1.292-.094-.083a1 1 0 00-1.32 1.497l2 2 .094.083a1 1 0 001.32-.083l4-4 .083-.094a1 1 0 00-.083-1.32z"></path>
                  </svg>
                </span>{" "}
                {t("available_balance")}
              </p>
            </div>
            <div className="efi-bank-head-status">
              <div className="efi-bank-status active"> {t("active")}</div>
              {/* <div className="efi-bank-status inactive">
                                Inactive
                            </div> */}
            </div>
          </div>
          <div className="efi-bank-view-content-body">
            <Row className="g-4">
              <Col lg={6}>
                <div className="efi-bank-account-details-car">
                  <div className="account-details-card-title">
                    <h4> {t("account_details")}</h4>
                  </div>
                  <div className="grid-layout-two">
                    <div className="grid-layout-card">
                      <h5>{t("account_id")}</h5>
                      <p>{account.unique_id}</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("label")}</h5>
                      <p>{account.label}</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("created_at")}</h5>
                      <p>{account.created_at_formatted}</p>
                    </div>
                  </div>
                  <div className="grid-layoit-two"></div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="efi-bank-account-details-car">
                  <div className="account-details-card-title">
                    <h4>{t("bank_details")}</h4>
                  </div>
                  <div className="grid-layout-three">
                    <div className="grid-layout-card">
                      <h5>{t("fees")}</h5>
                      <p>10%</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("sponsor_bank_name")}</h5>
                      <p>HDFC BANK LTD</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("interest")}</h5>
                      <p>18%</p>
                    </div>
                  </div>
                  <div className="grid-layoit-two"></div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="efi-bank-account-details-car">
                  <div className="account-details-card-title">
                    <h4>{t("payment_details")}</h4>
                  </div>
                  <div className="grid-layout-three">
                    <div className="grid-layout-card">
                      <h5>{t("currency")}</h5>
                      <p>{account.currency}</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("account_number")}</h5>
                      <p>{account.account_number}</p>
                    </div>
                    <div className="grid-layout-card">
                      <h5>{t("type")}</h5>
                      <p>{account.account_type}</p>
                    </div>
                  </div>
                  <div className="grid-layoit-two"></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountViewDetails;
