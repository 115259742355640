import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  businessInfoUpdate: getCommonInitialState(),
  businessUserStore: getCommonInitialState(),
  businessUserList: getCommonInitialState(),
  businessUserShow: getCommonInitialState(),
  updateBusinessDocument: getCommonInitialState(),
  businessDocumentsInfo: getCommonInitialState(),
  businessDocumentsStatus: getCommonInitialState(),
  personalDocumentsStatus: getCommonInitialState(),
};

const BusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    businessInfoUpdateStart: (state) => {
      state.businessInfoUpdate = getCommonStart();
    },
    businessInfoUpdateSuccess: (state, action) => {
      state.businessInfoUpdate = getCommonSuccess(action.payload);
    },
    businessInfoUpdateFailure: (state, action) => {
      state.businessInfoUpdate = getCommonFailure(action.payload);
    },

    businessUserStoreStart: (state) => {
      state.businessUserStore = getCommonStart();
    },
    businessUserStoreSuccess: (state, action) => {
      state.businessUserStore = getCommonSuccess(action.payload);
    },
    businessUserStoreFailure: (state, action) => {
      state.businessUserStore = getCommonFailure(action.payload);
    },

    businessUserListStart: (state) => {
      state.businessUserList = getCommonStart();
    },
    businessUserListSuccess: (state, action) => {
      state.businessUserList = getCommonSuccess(action.payload);
    },
    businessUserListFailure: (state, action) => {
      state.businessUserList = getCommonFailure(action.payload);
    },

    businessUserShowStart: (state) => {
      state.businessUserShow = getCommonStart();
    },
    businessUserShowSuccess: (state, action) => {
      state.businessUserShow = getCommonSuccess(action.payload);
    },
    businessUserShowFailure: (state, action) => {
      state.businessUserShow = getCommonFailure(action.payload);
    },

    updateBusinessDocumentStart: (state) => {
      state.updateBusinessDocument = getCommonStart();
    },
    updateBusinessDocumentSuccess: (state, action) => {
      state.updateBusinessDocument = getCommonSuccess(action.payload);
    },
    updateBusinessDocumentFailure: (state, action) => {
      state.updateBusinessDocument = getCommonFailure(action.payload);
    },

    businessDocumentsInfoStart: (state) => {
      state.businessDocumentsInfo = getCommonStart();
    },
    businessDocumentsInfoSuccess: (state, action) => {
      state.businessDocumentsInfo = getCommonSuccess(action.payload);
    },
    businessDocumentsInfoFailure: (state, action) => {
      state.businessDocumentsInfo = getCommonFailure(action.payload);
    },

    businessDocumentsStatusStart: (state) => {
      state.businessDocumentsStatus = getCommonStart();
    },
    businessDocumentsStatusSuccess: (state, action) => {
      state.businessDocumentsStatus = getCommonSuccess(action.payload);
    },
    businessDocumentsStatusFailure: (state, action) => {
      state.businessDocumentsStatus = getCommonFailure(action.payload);
    },

    personalDocumentsStatusStart: (state) => {
      state.personalDocumentsStatus = getCommonStart();
    },
    personalDocumentsStatusSuccess: (state, action) => {
      state.personalDocumentsStatus = getCommonSuccess(action.payload);
    },
    personalDocumentsStatusFailure: (state, action) => {
      state.personalDocumentsStatus = getCommonFailure(action.payload);
    },
  },
});

export const {
  businessInfoUpdateFailure,
  businessInfoUpdateStart,
  businessInfoUpdateSuccess,
  businessUserStoreFailure,
  businessUserStoreStart,
  businessUserStoreSuccess,
  businessUserListFailure,
  businessUserListStart,
  businessUserListSuccess,
  businessUserShowFailure,
  businessUserShowStart,
  businessUserShowSuccess,
  updateBusinessDocumentFailure,
  updateBusinessDocumentStart,
  updateBusinessDocumentSuccess,
  businessDocumentsInfoFailure,
  businessDocumentsInfoStart,
  businessDocumentsInfoSuccess,
  businessDocumentsStatusFailure,
  businessDocumentsStatusStart,
  businessDocumentsStatusSuccess,
  personalDocumentsStatusFailure,
  personalDocumentsStatusStart,
  personalDocumentsStatusSuccess,
} = BusinessSlice.actions;

export default BusinessSlice.reducer;
