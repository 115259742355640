import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    withdrawalList: getCommonInitialState(),
    withdrawalView: getCommonInitialState(),
    withdrawalCancel: getCommonInitialState(),
    userWallet: getCommonInitialState(),
    requestWithdrawal: getCommonInitialState(),

};

const WithdrawalSlice = createSlice({
    name: "withdrawal",
    initialState,
    reducers: {

        withdrawalListStart: (state) => { state.withdrawalList = getCommonStart() },
        withdrawalListSuccess: (state, action) => { state.withdrawalList = getCommonSuccess(action.payload) },
        withdrawalListFailure: (state, action) => { state.withdrawalList = getCommonFailure(action.payload) },

        withdrawalViewStart: (state) => { state.withdrawalView = getCommonStart() },
        withdrawalViewSuccess: (state, action) => { state.withdrawalView = getCommonSuccess(action.payload) },
        withdrawalViewFailure: (state, action) => { state.withdrawalView = getCommonFailure(action.payload) },

        withdrawalCancelStart: (state) => { state.withdrawalCancel = getCommonStart() },
        withdrawalCancelSuccess: (state, action) => { state.withdrawal = getCommonSuccess(action.payload) },
        withdrawalCancelFailure: (state, action) => { state.withdrawalCancel = getCommonFailure(action.payload) },

        userWalletStart: (state) => { state.userWallet = getCommonStart() },
        userWalletSuccess: (state, action) => { state.userWallet = getCommonSuccess(action.payload) },
        userWalletFailure: (state, action) => { state.userWallet = getCommonFailure(action.payload) },

        requestWithdrawalStart: (state) => { state.requestPayOut = getCommonStart() },
        requestWithdrawalSuccess: (state, action) => { state.requestPayOut = getCommonSuccess(action.payload) },
        requestWithdrawalFailure: (state, action) => { state.requestPayOut = getCommonFailure(action.payload) },

    }
});


export const {
    withdrawalListFailure,
    withdrawalListStart,
    withdrawalListSuccess,
    withdrawalViewFailure,
    withdrawalViewStart,
    withdrawalViewSuccess,
    withdrawalCancelStart,
    withdrawalCancelSuccess,
    withdrawalCancelFailure,
    userWalletFailure,
    userWalletStart,
    userWalletSuccess,
    requestWithdrawalStart,
    requestWithdrawalSuccess,
    requestWithdrawalFailure

} = WithdrawalSlice.actions;

export default WithdrawalSlice.reducer;