import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Image,
  Row,
  Col,
  InputGroup,
  Carousel,
} from "react-bootstrap";
import "./UserAuth.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginFailure, loginStart } from "../../store/slices/AdminSlice";
import OtpVerification from "./OtpVerification";
import { ButtonLoader } from "../Helper/Loader";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Authenticator from "./Authenticator";

const LoginIndex = (props) => {

  const t = useTranslation("login");
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.admin.login);
  const profile = useSelector((state) => state.admin.profile);
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const [skipRender, setSkipRender] = useState(true);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const userId = useSelector((state) => state.admin.userId);
  const [authenticatorModal, setAuthenticatorModal] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^\S*$/, t("password.no_spaces"))
      .min(6, t("required_note"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleLogin = (values) => {
    dispatch(loginStart({
      ...values,
      browser_id: userId,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      if (login.data.user.email_status == 0) {
        setShowOTPVerifyModal({
          ...login.data.user,
          type: "email",
        });
      } else if (login.data.google2fa_secret && login.data.user.is_initial_two_step_auth_enabled == 0) {
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
      }
      else if (login.data.user.tfa_status == 1)
        setShowOTPVerifyModal({
          ...login.data.user,
          type: "2fa",
        });
      else navigate("/");
    }
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.google2fa_secret) {
        setShowOTPVerifyModal(false);
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
        dispatch(loginFailure());
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [emailVerify]);


  // useEffect(() => {
  //   if (
  //     !skipRender &&
  //     !profile.loading &&
  //     Object.keys(profile.data).length > 0
  //   ) if(profile.data.is_initial_two_step_auth_enabled == 1){
  //     navigate("/");
  //   }
  //   setSkipRender(false);
  // }, [profile]);


  return (
    <>
      <div className="login-page-sec">
        <div className="login-page-box">
          <Row className="g-0">
            <Col md={6}>
              <div className="login-left-card">
                <div className="login-auth-top-frame">
                  <LazyLoadImage
                    className="login-auth-logo"
                    effect="blur"
                    src={
                      window.location.origin +
                      "/img/efi-bank-white-logo.png"
                    }
                  />
                </div>
                {/* <div className="login-page-img-sec"> */}
                <div className="login-auth-middle-frame">
                  <LazyLoadImage
                    className="login-auth-avater"
                    effect="blur"
                    src={
                      window.location.origin +
                      "/img/feature/auth/login-auth.png"
                    }
                  />
                  <div className="login-auth-info">
                    <h2>Secure Access</h2>
                    <p>
                      Welcome back to EFI Bank, where your financial security is our top priority. Log in to manage your accounts with ease.
                    </p>
                  </div>
                </div>
                <div className="login-auth-footer-frame">
                  <Carousel>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#0194ff" class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                          <span> Banking Simplified </span>
                        </h3>
                        <p>
                          EFI Bank brings you a straightforward banking experience, with all the tools you need just a click away.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#0194ff" class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                          <span> Identity Verification </span>
                        </h3>
                        <p>
                          Simplify your KYC process—fast and reliable verification to secure your financial dealings.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#0194ff" class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                          <span> Enhanced Security </span>
                        </h3>
                        <p>
                          Your security is our priority—EFI Bank ensures your financial data stays protected with robust encryption.
                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
                {/* </div> */}
              </div>
            </Col>
            <Col md={6}>
              <div className="login-page-form-box">
                <Row className="align-items-center justify-content-center g-0">
                  <Col lg={9} xl={9} md={12}>
                    <div className="efi-bank-auth-form">
                      <div className="efi-bank-auth-title">
                        <h3>Welcome Back!</h3>
                        <p>
                          Please log in with your Email ID and password to continue to your personalized banking dashboard.
                        </p>
                      </div>
                      <div className="efi-bank-auth-form-box">
                        <Formik
                          initialValues={{
                            email: "",
                            password: "",
                          }}
                          validationSchema={loginSchema}
                          onSubmit={handleLogin}
                          innerRef={formRef}
                        >
                          {({ setFieldValue, values, errors, touched }) => (
                            <FORM>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup className={`${(errors.email && touched.email) ? "input-group-error" : ""}`}>
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                                      ></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="email-addon"
                                  />
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup className={`${(errors.password && touched.password) ? "input-group-error" : ""}`}>
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M19.79 4.22c-2.96-2.95-7.76-2.95-10.7 0-2.07 2.05-2.69 5-1.89 7.6l-4.7 4.7c-.33.34-.56 1.01-.49 1.49l.3 2.18c.11.72.78 1.4 1.5 1.5l2.18.3c.48.07 1.15-.15 1.49-.5l.82-.82c.2-.19.2-.51 0-.71l-1.94-1.94a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.95 1.95c.19.19.51.19.7 0l2.12-2.11c2.59.81 5.54.18 7.6-1.87 2.95-2.95 2.95-7.76 0-10.71zM14.5 12a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
                                      ></path>
                                      <path fill="#171717" d="M14.5 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="password-input form-control"
                                    name="password"
                                    type={showPassword ? "password" : "text"}
                                    placeholder="Password"
                                    aria-label="Password"
                                    aria-describedby="Password-addon"
                                    maxLength={20}
                                  />
                                  <InputGroup.Text
                                    className="password-icon"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {!showPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                        ></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                        ></path>
                                        <path
                                          fill="#c3c7ca"
                                          d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                        ></path>
                                      </svg>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="password"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="efi-auth-extra-action">
                                <div className="efi-auth-remember-frame">
                                  {/* <Checkbox>Remember Me</Checkbox> */}
                                </div>
                                <div
                                  className="efi-auth-forget-password"
                                  onClick={() => navigate("/forgot-password")}
                                >
                                  Forgot Password ?
                                </div>
                              </div>

                              <div className="efi-auth-signin">
                                <Button
                                  className="efi-primary-btn w-100"
                                  type="submit"
                                  disabled={login.buttonDisable}
                                >
                                  {login.buttonDisable ? (
                                    <ButtonLoader varient="black" />
                                  ) : (
                                    t("heading")
                                  )}
                                </Button>
                                <p>
                                  {t("create_account")}{" "}
                                  <span
                                    className="sign-up"
                                    onClick={() => navigate("/register")}
                                  >
                                    {t("register")}
                                  </span>
                                </p>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </Col>
                </Row>
                {showOTPVerifyModal ? (
                  <OtpVerification
                    showOtpVerify={showOTPVerifyModal}
                    closeOtpVerifyModal={closeOtpVerifyModal}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {authenticatorModal ? (
        <Authenticator
          authenticatorModal={authenticatorModal}
          authentication={emailVerify.data?.google2fa_secret ? emailVerify : login}
          login={1}
          closeAuthenticatorModal={() => setAuthenticatorModal(false)}
        />
      ) : null}
    </>
  );
};

export default LoginIndex;
