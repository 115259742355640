import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/slices/AdminSlice";
import EmptyHeader from "./Header/EmptyHeader"
import PageLoader from "../Helper/PageLoader";
import { Navigate } from "react-router-dom";
import SidebarIndex from "./Sidebar/SidebarIndex";
import MainHeader from "./Header/MainHeader";

const AuthLayout = ({ children }) => {
  return(
    <div style={{position:"relative"}}>
    <EmptyHeader/>
    {children}
    </div>
  )
};

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {

  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  return (
    <>
      {profile.loading ? <PageLoader/> : Object.keys(profile.data).length > 0 ? <div className={`admin-ui-sec ${!profile.loading && Object.keys(profile.data).length > 0 && profile.data.role == 2 ? 'agent-panel' : 'user-panel'}`}>
        <SidebarIndex />
        <div
          className={`admin-ui-main-wrapper ${
            sidebar ? "admin-collapse-main-wrapper" : ""
          }`}
        >
          <MainHeader />
          {children}
        </div>
      </div> : <Navigate to={"/login"}/>}
    </>
  );
};

const OnboardingLayout = ({ children }) => {

  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  return (
    <>
      {profile.loading ? <PageLoader/> : Object.keys(profile.data).length > 0 ? <div className={`${!profile.loading && Object.keys(profile.data).length > 0 && profile.data.role == 2 ? 'agent-panel' : 'user-panel'}`}>
        <div>
          {children}
        </div>
      </div> : <Navigate to={"/login"}/>}
    </>
  );
};

const EmptyAdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  useEffect(() => {
    dispatch(profileStart());
  }, []);
  return (
    <>
      {!profile.loading && Object.keys(profile.data).length > 0 ? (
        <div className="main-wrapper">
          {children}
        </div>
      ) : null}
    </>
  );
};

export { AuthLayout, EmptyLayout, AdminLayout, EmptyAdminLayout, OnboardingLayout };
