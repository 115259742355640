import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    bankAccountsList: getCommonInitialState(),
    bankAccountsStore: getCommonInitialState(),
    bankAccountsView: getCommonInitialState(),
    bankAccountsDelete: getCommonInitialState(),
};

const BankAccountsSlice = createSlice({
    name: "banks",
    initialState,
    reducers: {

        bankAccountsListStart: (state) => { state.bankAccountsList = getCommonStart() },
        bankAccountsListSuccess: (state, action) => { state.bankAccountsList = getCommonSuccess(action.payload) },
        bankAccountsListFailure: (state, action) => { state.bankAccountsList = getCommonFailure(action.payload) },

        bankAccountsStoreStart: (state) => { state.bankAccountsStore = getCommonStart() },
        bankAccountsStoreSuccess: (state, action) => { state.bankAccountsStore = getCommonSuccess(action.payload) },
        bankAccountsStoreFailure: (state, action) => { state.bankAccountsStore = getCommonFailure(action.payload) },

        bankAccountsViewStart: (state) => { state.bankAccountsView = getCommonStart() },
        bankAccountsViewSuccess: (state, action) => { state.bankAccountsView = getCommonSuccess(action.payload) },
        bankAccountsViewFailure: (state, action) => { state.bankAccountsView = getCommonFailure(action.payload) },

        bankAccountsDeleteStart: (state, action) => { state.bankAccountsDelete = getCommonStart(action.payload) },
        bankAccountsDeleteSuccess: (state, action) => { state.bankAccountsDelete = getCommonSuccess(action.payload) },
        bankAccountsDeleteFailure: (state, action) => { state.bankAccountsDelete = getCommonFailure(action.payload) },

    }
});

export const {
    bankAccountsDeleteFailure,
    bankAccountsDeleteStart,
    bankAccountsDeleteSuccess,
    bankAccountsListFailure,
    bankAccountsListStart,
    bankAccountsListSuccess,
    bankAccountsStoreFailure,
    bankAccountsStoreStart,
    bankAccountsStoreSuccess,
    bankAccountsViewFailure,
    bankAccountsViewStart,
    bankAccountsViewSuccess,
} = BankAccountsSlice.actions;

export default BankAccountsSlice.reducer;