import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
const SuccessPage = () => {
  const t = useTranslation("onboarding.onboarding_form.success_page");
  return (
    <>
      <div className="onboarding-final-frame">
        <div className="onboarding-inner-area">
          <div className="welcome-onboarding-img-sec">
            <Image
              src={
                window.location.origin +
                "/img/feature/onboarding/illustration-four.svg"
              }
              className="illustration-avater"
            />
          </div>
          <div className="welcome-onboarding-info-sec">
            <h4>{t("heading")}</h4>
            <p>{t("content")}</p>
            <Link to="/" className="efi-primary-btn">
              {t("begin")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessPage;
