import React from 'react';
import { Tab, Nav, Image } from 'react-bootstrap';
import "./userAccess.css"
import TeamMenberListTable from './TeamMenberListTable';
import RolesAndResponsibility from './RolesAndResponsibility/RolesAndResponsibility';

const ExpenseMain = () => {

  return (
    <div className="full-body-card-wrapped">
      <Tab.Container id="left-tabs-example" defaultActiveKey="team-member">
        <div className="common-full-card payment-sec">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link className="team-member" eventKey="team-member">
                <div className="navgation-card-icon">
                  <Image
                    src={
                      window.location.origin + "/img/icon/communication.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <h4>Team Member</h4>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="roles" eventKey="roles">
                <div className="navgation-card-icon">
                  <Image
                    src={
                      window.location.origin + "/img/icon/management.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <h4>Roles & Responsibility</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="payment-tab-content">
          <Tab.Content>
            <Tab.Pane eventKey="team-member">
              <TeamMenberListTable />
            </Tab.Pane>
            <Tab.Pane eventKey="roles">
              <RolesAndResponsibility />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default ExpenseMain;