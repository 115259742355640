import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    cardsList: getCommonInitialState(),
    cardView: getCommonInitialState(),
    virtualCardCreate: getCommonInitialState(),
    physicalCardAssign: getCommonInitialState(),
    activateCard: getCommonInitialState(),
    setPin: getCommonInitialState(),
    cardBlockUnblock: getCommonInitialState(),
};

const CardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {

        cardsListStart: (state) => { state.cardsList = getCommonStart() },
        cardsListSuccess: (state, action) => { state.cardsList = getCommonSuccess(action.payload) },
        cardsListFailure: (state, action) => { state.cardsList = getCommonFailure(action.payload) },

        cardViewStart: (state, action) => { state.cardView = getCommonStart(action.payload) },
        cardViewSuccess: (state, action) => { state.cardView = getCommonSuccess(action.payload) },
        cardViewFailure: (state, action) => { state.cardView = getCommonFailure(action.payload) },

        virtualCardCreateStart: (state) => { state.virtualCardCreate = getCommonStart() },
        virtualCardCreateSuccess: (state, action) => { state.virtualCardCreate = getCommonSuccess(action.payload) },
        virtualCardCreateFailure: (state, action) => { state.virtualCardCreate = getCommonFailure(action.payload) },

        physicalCardAssignStart: (state) => { state.physicalCardAssign = getCommonStart() },
        physicalCardAssignSuccess: (state, action) => { state.physicalCardAssign = getCommonSuccess(action.payload) },
        physicalCardAssignFailure: (state, action) => { state.physicalCardAssign = getCommonFailure(action.payload) },

        activateCardStart: (state) => { state.activateCard = getCommonStart() },
        activateCardSuccess: (state, action) => { state.activateCard = getCommonSuccess(action.payload) },
        activateCardFailure: (state, action) => { state.activateCard = getCommonFailure(action.payload) },

        setPinStart: (state) => { state.setPin = getCommonStart() },
        setPinSuccess: (state, action) => { state.setPin = getCommonSuccess(action.payload) },
        setPinFailure: (state, action) => { state.setPin = getCommonFailure(action.payload) },

        cardBlockUnblockStart: (state, action) => { state.cardBlockUnblock = getCommonStart(action.payload) },
        cardBlockUnblockSuccess: (state, action) => { state.cardBlockUnblock = getCommonSuccess(action.payload) },
        cardBlockUnblockFailure: (state, action) => { state.cardBlockUnblock = getCommonFailure(action.payload) },

    }
});

export const {
    cardsListFailure,
    cardsListStart,
    cardsListSuccess,
    cardViewFailure,
    cardViewStart,
    cardViewSuccess,
    virtualCardCreateFailure,
    virtualCardCreateStart,
    virtualCardCreateSuccess,
    physicalCardAssignFailure,
    physicalCardAssignStart,
    physicalCardAssignSuccess,
    activateCardFailure,
    activateCardStart,
    activateCardSuccess,
    setPinFailure,
    setPinStart,
    setPinSuccess,
    cardBlockUnblockFailure,
    cardBlockUnblockStart,
    cardBlockUnblockSuccess,
} = CardSlice.actions;

export default CardSlice.reducer;
