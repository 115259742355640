import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    accountsList: getCommonInitialState(),
    accountsStore: getCommonInitialState(),
    accountsView: getCommonInitialState(),
    accountsStatements: getCommonInitialState(),
    contactsList: getCommonInitialState(),
    contactsStore: getCommonInitialState(),
    contactsView: getCommonInitialState(),
    contactsDelete: getCommonInitialState(),
    allContactsList: getCommonInitialState(),
    findUser: getCommonInitialState(),
    virtualAccount: getCommonInitialState(),
};

const AccountsSlice = createSlice({
    name: "expense",
    initialState,
    reducers: {

        accountsListStart: (state) => { state.accountsList = getCommonStart() },
        accountsListSuccess: (state, action) => { state.accountsList = getCommonSuccess(action.payload) },
        accountsListFailure: (state, action) => { state.accountsList = getCommonFailure(action.payload) },

        accountsStoreStart: (state) => { state.accountsStore = getCommonStart() },
        accountsStoreSuccess: (state, action) => { state.accountsStore = getCommonSuccess(action.payload) },
        accountsStoreFailure: (state, action) => { state.accountsStore = getCommonFailure(action.payload) },

        accountsViewStart: (state) => { state.accountsView = getCommonStart() },
        accountsViewSuccess: (state, action) => { state.accountsView = getCommonSuccess(action.payload) },
        accountsViewFailure: (state, action) => { state.accountsView = getCommonFailure(action.payload) },

        accountsStatementsStart: (state) => { state.accountsStatements = getCommonStart() },
        accountsStatementsSuccess: (state, action) => { state.accountsStatements = getCommonSuccess(action.payload) },
        accountsStatementsFailure: (state, action) => { state.accountsStatements = getCommonFailure(action.payload) },

        contactsListStart: (state) => { state.contactsList = getCommonStart() },
        contactsListSuccess: (state, action) => { state.contactsList = getCommonSuccess(action.payload) },
        contactsListFailure: (state, action) => { state.contactsList = getCommonFailure(action.payload) },

        contactsStoreStart: (state) => { state.contactsStore = getCommonStart() },
        contactsStoreSuccess: (state, action) => { state.contactsStore = getCommonSuccess(action.payload) },
        contactsStoreFailure: (state, action) => { state.contactsStore = getCommonFailure(action.payload) },

        contactsViewStart: (state) => { state.contactsView = getCommonStart() },
        contactsViewSuccess: (state, action) => { state.contactsView = getCommonSuccess(action.payload) },
        contactsViewFailure: (state, action) => { state.contactsView = getCommonFailure(action.payload) },

        contactsDeleteStart: (state, action) => { state.contactsDelete = getCommonStart(action.payload) },
        contactsDeleteSuccess: (state, action) => { state.contactsDelete = getCommonSuccess(action.payload) },
        contactsDeleteFailure: (state, action) => { state.contactsDelete = getCommonFailure(action.payload) },

        allContactsListStart: (state) => { state.allContactsList = getCommonStart() },
        allContactsListSuccess: (state, action) => { state.allContactsList = getCommonSuccess(action.payload) },
        allContactsListFailure: (state, action) => { state.allContactsList = getCommonFailure(action.payload) },

        findUserStart: (state) => { state.findUser = getCommonStart() },
        findUserSuccess: (state, action) => { state.findUser = getCommonSuccess(action.payload) },
        findUserFailure: (state, action) => { state.findUser = getCommonFailure(action.payload) },

        virtualAccountStart: (state) => { state.virtualAccount = getCommonStart() },
        virtualAccountSuccess: (state, action) => { state.virtualAccount = getCommonSuccess(action.payload) },
        virtualAccountFailure: (state, action) => { state.virtualAccount = getCommonFailure(action.payload) },

    }
});

export const {
    accountsListFailure,
    accountsListStart,
    accountsListSuccess,
    accountsStoreFailure,
    accountsStoreStart,
    accountsStoreSuccess,
    accountsViewFailure,
    accountsViewStart,
    accountsViewSuccess,
    accountsStatementsFailure,
    accountsStatementsStart,
    accountsStatementsSuccess,
    contactsDeleteFailure,
    contactsDeleteStart,
    contactsDeleteSuccess,
    contactsListFailure,
    contactsListStart,
    contactsListSuccess,
    contactsStoreFailure,
    contactsStoreStart,
    contactsStoreSuccess,
    contactsViewFailure,
    contactsViewStart,
    contactsViewSuccess,
    allContactsListFailure,
    allContactsListStart,
    allContactsListSuccess,
    findUserFailure,
    findUserStart,
    findUserSuccess,
    virtualAccountFailure,
    virtualAccountStart,
    virtualAccountSuccess
} = AccountsSlice.actions;

export default AccountsSlice.reducer;