import React from 'react';
import { Tab, Nav, Image } from 'react-bootstrap';
import "./expense.css"
import InvoiceListTable from './InvoiceListTable';
import BillListTable from './BillListTable';

const ExpenseMain = () => {

  return (
    <div className="full-body-card-wrapped payment-sec">
      <Tab.Container id="left-tabs-example" defaultActiveKey="invoice">
        <div className="common-full-card">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link className="invoice" eventKey="invoice">
                <div className="navgation-card-icon">
                  <Image
                    src={
                      window.location.origin + "/img/icon/invoice.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <h4>Invoice</h4>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="bill-payment" eventKey="bill-payment">
                <div className="navgation-card-icon">
                  <Image
                    src={
                      window.location.origin + "/img/icon/bill-payments.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <h4>Bill Payment</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="payment-tab-content">
          <Tab.Content>
            <Tab.Pane eventKey="invoice">
              <InvoiceListTable />
            </Tab.Pane>
            <Tab.Pane eventKey="bill-payment">
              <BillListTable />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default ExpenseMain;