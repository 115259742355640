import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    dashboardAccountList: getCommonInitialState(),
    dashboardChatList: getCommonInitialState(),
};

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {

        dashboardAccountListStart: (state) => { state.dashboardAccountList = getCommonStart() },
        dashboardAccountListSuccess: (state, action) => { state.dashboardAccountList = getCommonSuccess(action.payload) },
        dashboardAccountListFailure: (state, action) => { state.dashboardAccountList = getCommonFailure(action.payload) },

        dashboardChatListStart: (state) => { state.dashboardChatList = getCommonStart() },
        dashboardChatListSuccess: (state, action) => { state.dashboardChatList = getCommonSuccess(action.payload) },
        dashboardChatListFailure: (state, action) => { state.dashboardChatList = getCommonFailure(action.payload) },

    }
});

export const {
    dashboardAccountListFailure,
    dashboardAccountListStart,
    dashboardAccountListSuccess,
    dashboardChatListFailure,
    dashboardChatListStart,
    dashboardChatListSuccess,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;