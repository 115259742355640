import React, { useEffect } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaticPageStart } from "../../store/slices/PageSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";

const StaticPageIndex = (props) => {

  const t = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.page.pageData);

  useEffect(() => {
    dispatch(fetchStaticPageStart({ static_page_unique_id: params.unique_id }));
    window.scrollTo(0, 0);
  }, [params.unique_id]);

  return (
    <div className="efi-bank-create-account-wrapped">
      <div className="static-page-sec">
        <div className="static-page-box">
          <Row className="justify-content-md-center">
            <Col md={12} xl={7} lg={8}>
              <div className="static-page-card">
                {pageData.loading ? (
                  <div className="static-page-content">
                    <Skeleton count={1} height={60} className="mb-2" />
                    <Skeleton count={1} height={175} />
                  </div>
                ) : Object.keys(pageData.data).length > 0 &&
                  Object.keys(pageData.data.static_page).length > 0 ? (
                  <div className="static-page-content">
                    <h1 className="text-capitalize">
                      {pageData.data.static_page.title}
                    </h1>
                    <p
                      className="text-capitalize"
                      dangerouslySetInnerHTML={{
                        __html: pageData.data.static_page.description,
                      }}
                    ></p>
                  </div>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StaticPageIndex;
