import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
// import {
//   cardBlockUnblockStart,
//   cardViewStart,
//   physicalCardAssignStart,
//   virtualCardCreateStart,
// } from "../../store/slices/CardSlice";
// import { transferMoneyStart } from "../../store/";
// import CopyToClipboard from "react-copy-to-clipboard";
import * as Yup from "yup";
import { generateBackupCodesStart } from "../../store/slices/UserAccessSlice";

const OtpVerification = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("settings.setting");
  const cardView = useSelector((state) => state.card.cardView);
  const [otp, setOtp] = useState("");
  const cardBlockUnblock = useSelector((state) => state.card.cardBlockUnblock);
  const [skipRender, setSkipRender] = useState(true);
  const transferMoney = useSelector((state) => state.payment.transferMoney);
  const [tfaShowPassword, setTfaShowPassword] = useState(true);
  const generateBackupCodes = useSelector(state => state.access.generateBackupCodes)

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleSubmit = (values) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        dispatch(generateBackupCodesStart({
          ...values,
          otp: otp
        }
        ));
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cardBlockUnblock.loading &&
      Object.keys(cardBlockUnblock.data).length > 0
    ) {
      props.closeOtpVerifyModal();
    }
    setSkipRender(false);
  }, [cardBlockUnblock]);

  const handleChange = (otp) => {
    // Check if the input is numeric
    if (/^\d*$/.test(otp)) {
      setOtp(otp);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="proxy-card-input-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sign-up-verification">
            <div className="otp-frame-title mt-3">
              <Image
                src={window.location.origin + "/img/feature/otp-icon.svg"}
              />
              <h2> Input 2FA code from virtual device dddd</h2>
              <p>
                Please input the code shown on your virtual device{" "}
                {/* {props.showOtpVerify.action == 4 ? "card details" : props.showOtpVerify.action == 5 ? "money transfer" : props.showOtpVerify.action == 3 ? "unblocking card" : "blocking card" } */}
              </p>
            </div>
            <Formik
              initialValues={{
                otp: "",
                password: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={tfaSchema}
            >
              {({ values, setFieldValue }) => (
                <FORM className="efi-bank-default-form">
                  <ul className="list-unstyled">
                    <li>
                      <div className="efi-bank-step-authentic-card">
                        <div className="efi-bank-step-count">1</div>
                        <div className="efi-bank-step-info">
                          <p>{t("enter_six_configuration_code")}</p>
                          <div className="">
                            <OtpInput
                              className="otp-box"
                              value={otp}
                              onChange={handleChange}
                              numInputs={6}
                              // renderSeparator={<span>-</span>}
                              renderInput={(props) => <input {...props} />}
                              isInputNum={true} // Restricts input to numbers only
                              shouldAutoFocus // Automatically focuses on the first input
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="efi-bank-step-authentic-card">
                        <div className="efi-bank-step-count">2</div>
                        <div className="efi-bank-default-form ">
                          <p>{t("password.enter_your_password")}</p>
                          <div className="mb-3" style={{ width: "100%" }}>
                            <InputGroup>
                              <Field
                                className="form-control"
                                name="password"
                                type={!tfaShowPassword ? "text" : "password"}
                                placeholder={t("password.placeholder")}
                                aria-label="Password"
                                aria-describedby="Password-addon"
                              />
                              <InputGroup.Text
                                className="password-icon"
                                onClick={() =>
                                  setTfaShowPassword(!tfaShowPassword)
                                }
                              >
                                {!tfaShowPassword ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#dde1e6"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-eye"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#dde1e6"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                    <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                    <path d="M3 3l18 18" />
                                  </svg>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="efi-bank-default-form">
                    <div className="form-modal-footer">
                      <Button
                        type="submit"
                        className="efi-primary-btn w-100"
                        onClick={() => handleSubmit(values)}
                        disabled={generateBackupCodes.buttonDisable}
                      >
                        {generateBackupCodes.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("generate")
                        )}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;
