import React from 'react';
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";

const SingleBeneficiary = (props) => {
    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "#fff!important",
            border: "1px solid #dde1e6!important",
            borderRadius: "12px!important",
            boxShadow: "none!important",
            height: "45px",
            display: "flex",
            alignItems: "center",
            alignItemsContent: "center",
            cursor: "pointer",
            fontSize: "0.95em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#dde1e6",
            fontSize: "0.95em",
            fontWeight: "500",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#000",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.95em",
            fontWeight: "500",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#32089F!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#dde1e6",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#f7f7f7" : "#fff",
                color: "#000",
                "&:hover": {
                    backgroundColor: "#f7f7f7",
                    color: "#000",
                },
            };
        },
    };
    const countryOptions =
        [
            "AT",
            "AD",
            "AU",
            "BD",
            "BE",
            "BG",
            "CH",
            "CN",
            "CY",
            "CZ",
            "DE",
            "DK",
            "EE",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "HK",
            "HR",
            "HU",
            "ID",
            "IE",
            "IN",
            "IS",
            "IT",
            "JP",
            "LI",
            "LK",
            "LT",
            "LU",
            "LV",
            "MC",
            "MT",
            "MY",
            "NL",
            "NO",
            "PH",
            "PL",
            "PT",
            "RO",
            "SE",
            "SI",
            "SK",
            "SM",
            "TH",
            "US",
            "VA",
            "VN",
        ];
    return (
        <>
            <div className="single-transaction-beneficiary">
                <div className="single-transaction-box-avater">
                    <Image
                        className="trans-beneficiary-avater"
                        src={window.location.origin + "/img/beneficiary-select.svg"}
                        type="image/png"
                    />
                </div>
                <div className="single-transaction-box-field">
                    <h4>Select Beneficiary</h4>
                    <p>Beneficiaries can be individuals, businesses, or charitable organizations. The account that receives the funds is called the beneficiary account</p>
                    <Select
                        options={countryOptions}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        placeholder="Country"
                    />
                </div>

                <div className="single-transaction-box-action w-100">
                    <Button className="efi-primary-btn  w-100" onClick={() => props.setStep(3)}>Next</Button>
                </div>
            </div>
        </>
    )
}

export default SingleBeneficiary;
