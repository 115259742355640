import React from 'react';
import { Image, Row, Col, Form, Button } from "react-bootstrap";

const TransactionSuccess = (props) => {
    return (
        <>
            <div className="single-transaction-final">
                <div className="single-avater">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="120"
                        height="120"
                        viewBox="0 0 24 24"
                    >
                        <g data-name="Layer 2">
                            <path
                                fill="#2196f3"
                                d="M15.14 20L12 22l-4-3a12.22 12.22 0 01-4.89-9.78V5.33a21.29 21.29 0 004.45-1.11A22 22 0 0012 2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11v4.34A12.21 12.21 0 0115.14 20z"
                            ></path>
                            <path
                                fill="#176ca5"
                                d="M20.89 5.33v4.34A12.21 12.21 0 0115.14 20L12 22V2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11z"
                            ></path>
                        </g>
                    </svg>
                    <h3>Review</h3>
                </div>
                <div className="single-space"></div>
                <div className="single-transaction-box-action">
                    <div className="single-transaction-info-box">
                        <div className="single-transaction-info-card">
                            <h4>Fees:</h4>
                            <h5>1.00 GST</h5>
                        </div>
                        <div className="single-transaction-info-card">
                            <h4>Exchange rate:</h4>
                            <h5>1.00 GST <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 64 64"
                                >
                                    <g fill="#0194ff">
                                        <path
                                            d="M53.121 13.594L36.08 4.25a2.027 2.027 0 00-2.977 1.773v2.9c-13.996.018-22.815 9.592-23.516 17.746l.088 2.693c5.963-6.725 15.16-9.554 22.605-9.216v3.8a2.027 2.027 0 002.81 1.851l17.796-7.639a2.559 2.559 0 00.235-4.564z"
                                            data-original="#000000"
                                        ></path>
                                        <path
                                            d="M12.104 29.877l-1.841 1.944a17.582 17.582 0 004.143 6.581.988.988 0 001.665-.725c-.226-4.1.79-10.006 1.86-12.35q-.363.195-.737.416a23.582 23.582 0 00-5.09 4.134zm-1.225 20.529l17.041 9.343a2.027 2.027 0 002.977-1.772v-2.9c13.996-.018 22.815-9.592 23.516-17.746l-.088-2.693c-5.963 6.725-15.16 9.554-22.605 9.216v-3.8a2.027 2.027 0 00-2.81-1.851l-17.796 7.639a2.559 2.559 0 00-.235 4.564z"
                                            data-original="#000000"
                                        ></path>
                                        <path
                                            d="M51.896 34.123l1.841-1.944a17.582 17.582 0 00-4.143-6.581.988.988 0 00-1.665.725c.226 4.1-.79 10.006-1.86 12.35q.363-.195.737-.416a23.582 23.582 0 005.09-4.134z"
                                            data-original="#000000"
                                        ></path>
                                    </g>
                                </svg>
                            </span> 999.00 SGT</h5>
                        </div>
                        <div className="single-transaction-info-card">
                            <h4>Total Payable:</h4>
                            <h5>134.00 GST</h5>
                        </div>
                    </div>
                </div>
                <div className="single-transaction-box-action w-100">
                    <Button className="efi-primary-btn  w-100" onClick={() => props.setStep(2)}>Done</Button>
                </div>
            </div>
        </>
    )
}

export default TransactionSuccess;
