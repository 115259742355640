import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    teamMemberListFailure,
    teamMemberListSuccess,
    roleAdminListFailure,
    roleAdminListSuccess,
    roleManagerListFailure,
    roleManagerListSuccess,
    roleFinanceListFailure,
    roleFinanceListSuccess,
    roleEmployerListFailure,
    roleEmployerListSuccess,
    addMemberFailure,
    addMemberSuccess,
    changeRoleFailure,
    changeRoleSuccess,
    generateBackupCodesStart,
    generateBackupCodesSuccess,
    generateBackupCodesFailure,
} from "../slices/UserAccessSlice";

function* teamMemberListAPI(action) {
    yield getCommonSaga({
        apiUrl: "teamMember_list",
        payload: action.payload,
        success: teamMemberListSuccess,
        failure: teamMemberListFailure,
    });
}

function* roleAdminListAPI(action) {
    yield getCommonSaga({
        apiUrl: "roleAdmin_list",
        payload: action.payload,
        success: roleAdminListSuccess,
        failure: roleAdminListFailure,
    });
}

function* roleManagerListAPI(action) {
    yield getCommonSaga({
        apiUrl: "roleManager_list",
        payload: action.payload,
        success: roleManagerListSuccess,
        failure: roleManagerListFailure,
    });
}

function* roleFinanceListAPI(action) {
    yield getCommonSaga({
        apiUrl: "roleFinance_list",
        payload: action.payload,
        success: roleFinanceListSuccess,
        failure: roleFinanceListFailure,
    });
}

function* roleEmployerListAPI(action) {
    yield getCommonSaga({
        apiUrl: "role_employer_list",
        payload: action.payload,
        success: roleEmployerListSuccess,
        failure: roleEmployerListFailure,
    });
}

function* addMemberAPI(action) {
    yield getCommonSaga({
        apiUrl: "add_member",
        payload: action.payload,
        success: addMemberSuccess,
        failure: addMemberFailure,
    });
}

function* changeRoleAPI(action) {
    yield getCommonSaga({
        apiUrl: "change_role",
        payload: action.payload,
        success: changeRoleSuccess,
        failure: changeRoleFailure,
    });
}

function* generateBackupCodesAPI(action) {
    yield getCommonSaga({
        apiUrl: "generate_backup_codes",
        payload: action.payload,
        success: generateBackupCodesSuccess,
        failure: generateBackupCodesFailure,
    });
}

export default function* UserAccessSaga() {
    yield all([
        yield takeLatest('access/teamMemberListStart', teamMemberListAPI),
        yield takeLatest('access/roleAdminListStart', roleAdminListAPI),
        yield takeLatest('access/roleManagerListStart', roleManagerListAPI),
        yield takeLatest('access/roleFinanceListStart', roleFinanceListAPI),
        yield takeLatest('access/roleEmployerListStart', roleEmployerListAPI),
        yield takeLatest('access/addMemberStart', addMemberAPI),
        yield takeLatest('access/changeRoleStart', changeRoleAPI),
        yield takeLatest('access/generateBackupCodesStart', generateBackupCodesAPI),

    ]);
}