import React from "react";
import { Table, Modal} from "react-bootstrap";


const ViewTransactionModal = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Transaction History Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>Date</td>
                <td>#20 Nov 2023</td>
              </tr>
              <tr>
                <td>Transaction Id</td>
                <td>#45687</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>500 USD</td>
              </tr>
              <tr>
                <td>Credit/Debit</td>
                <td><div className='green-badge'>Credit</div></td>
              </tr>
              <tr>
                <td>Status</td>
                <td><div className='green-badge'>Success</div></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransactionModal;
