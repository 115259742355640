import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    walletListFailure,
    walletListSuccess,
    walletViewFailure,
    walletViewSuccess,
} from "../slices/WalletSlice";

function* walletListAPI(action) {
    yield getCommonSaga({
        apiUrl: "wallet_list",
        payload: action.payload,
        successNotify: false,
        success: walletListSuccess,
        failure: walletListFailure,
    });
}

function* walletViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "wallet_view",
        payload: action.payload,
        successNotify: false,
        success: walletViewSuccess,
        failure: walletViewFailure,
    });
}

export default function* WalletSaga() {
    yield all([
        yield takeLatest('wallet/walletListStart', walletListAPI),
        yield takeLatest('wallet/walletViewStart', walletViewAPI),


    ]);
}