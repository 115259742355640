import React from "react";
import { Image } from "react-bootstrap";

const NoDataFoundNew = () => {
  return (
    <div className="no-data-found-sec">
      <Image
        className="create-account-avater"
        src={window.location.origin + "/img/nodata-found-img.svg"}
      />
      <h5>No Data Found</h5>
    </div>
  );
};

export default NoDataFoundNew;
