import CommonSlice from "./CommonSlice";
import AdminSlice from "./AdminSlice";
import WithdrawalSlice from "./WithdrawalSlice";
import WalletSlice from "./WalletSlice";
import PaymentSlice from "./PaymentSlice";
import ExpenseSlice from "./ExpenseSlice";
import UserAccessSlice from "./UserAccessSlice";
import DashboardSlice from "./DashboardSlice";
import AccountsSlice from "./AccountsSlice";
import PageSlice from "./PageSlice";
import CardSlice from "./CardSlice";
import BankAccountsSlice from "./BankAccountsSlice";
import BusinessSlice from "./BusinessSlice";
import BeneficiariesSlice from "../slices/BeneficiariesSlice";

export const reducers = {
  common: CommonSlice,
  admin: AdminSlice,
  withdrawal: WithdrawalSlice,
  wallet: WalletSlice,
  payment: PaymentSlice,
  expense: ExpenseSlice,
  access: UserAccessSlice,
  dashboard: DashboardSlice,
  accounts: AccountsSlice,
  page: PageSlice,
  card: CardSlice,
  banks: BankAccountsSlice,
  business: BusinessSlice,
  beneficiaries: BeneficiariesSlice,
};
