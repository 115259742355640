import React from 'react';
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";

const SingleTransactionFields = (props) => {
    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "transparent!important",
            borderRadius: "12px!important",
            boxShadow: "none!important",
            height: "45px",
            display: "flex",
            alignItems: "center",
            alignItemsContent: "center",
            cursor: "pointer",
            fontSize: "0.95em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#fff",
            fontSize: "0.95em",
            fontWeight: "500",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#000",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.95em",
            fontWeight: "500",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#32089F!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#dde1e6",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#f7f7f7" : "#fff",
                color: "#000",
                "&:hover": {
                    backgroundColor: "#f7f7f7",
                    color: "#000",
                },
            };
        },
    };
    const countryOptions =
        [
            "AT",
            "AD",
            "AU",
            "BD",
            "BE",
            "BG",
            "CH",
            "CN",
            "CY",
            "CZ",
            "DE",
            "DK",
            "EE",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "HK",
            "HR",
            "HU",
            "ID",
            "IE",
            "IN",
            "IS",
            "IT",
            "JP",
            "LI",
            "LK",
            "LT",
            "LU",
            "LV",
            "MC",
            "MT",
            "MY",
            "NL",
            "NO",
            "PH",
            "PL",
            "PT",
            "RO",
            "SE",
            "SI",
            "SK",
            "SM",
            "TH",
            "US",
            "VA",
            "VN",
        ];
    return (
        <>
          <div className="single-transaction-frame">
            <div className="single-transaction-titles">
                <h4>Exchange Calculator</h4>
            </div>
            <div className="titles-space"></div>
            <div className="single-transaction-content">
                <Form>
                    <div className="single-transaction-box-field mb-3">
                        <Form.Label className="default-form-label">
                            Send
                        </Form.Label>
                        <div className="single-transaction-box">
                            <div className="single-transaction-fields">

                                <Form.Group>
                                    <Form.Control type="email" placeholder=" Amount Sended" />
                                </Form.Group>
                            </div>
                            <div className="single-transaction-country">
                                <Select
                                    options={countryOptions}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    placeholder="Country"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="single-transaction-box-field">
                        <Form.Label className="default-form-label">
                            Received
                        </Form.Label>
                        <div className="single-transaction-box">
                            <div className="single-transaction-fields">

                                <Form.Group>
                                    <Form.Control type="email" placeholder="Amount Received" />
                                </Form.Group>
                            </div>
                            <div className="single-transaction-country">
                                <Select
                                    options={countryOptions}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    placeholder="Country"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="single-space"></div>
                    <div className="single-transaction-info-box">
                        <div className="single-transaction-info-card">
                            <h4>Fees:</h4>
                            <h5>1.00 GST</h5>
                        </div>
                        <div className="single-transaction-info-card">
                            <h4>Exchange rate:</h4>
                            <h5>1.00 GST <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 64 64"
                                >
                                    <g fill="#0194ff">
                                        <path
                                            d="M53.121 13.594L36.08 4.25a2.027 2.027 0 00-2.977 1.773v2.9c-13.996.018-22.815 9.592-23.516 17.746l.088 2.693c5.963-6.725 15.16-9.554 22.605-9.216v3.8a2.027 2.027 0 002.81 1.851l17.796-7.639a2.559 2.559 0 00.235-4.564z"
                                            data-original="#000000"
                                        ></path>
                                        <path
                                            d="M12.104 29.877l-1.841 1.944a17.582 17.582 0 004.143 6.581.988.988 0 001.665-.725c-.226-4.1.79-10.006 1.86-12.35q-.363.195-.737.416a23.582 23.582 0 00-5.09 4.134zm-1.225 20.529l17.041 9.343a2.027 2.027 0 002.977-1.772v-2.9c13.996-.018 22.815-9.592 23.516-17.746l-.088-2.693c-5.963 6.725-15.16 9.554-22.605 9.216v-3.8a2.027 2.027 0 00-2.81-1.851l-17.796 7.639a2.559 2.559 0 00-.235 4.564z"
                                            data-original="#000000"
                                        ></path>
                                        <path
                                            d="M51.896 34.123l1.841-1.944a17.582 17.582 0 00-4.143-6.581.988.988 0 00-1.665.725c.226 4.1-.79 10.006-1.86 12.35q.363-.195.737-.416a23.582 23.582 0 005.09-4.134z"
                                            data-original="#000000"
                                        ></path>
                                    </g>
                                </svg>
                            </span> 999.00 SGT</h5>
                        </div>
                        <div className="single-transaction-info-card">
                            <h4>Total Payable:</h4>
                            <h5>134.00 GST</h5>
                        </div>
                    </div>
                    <div className="single-transaction-box-action">
                        <Button className="efi-primary-btn  w-100"                 onClick={() => props.setStep(2)}

                >Confirm Amount</Button>
                    </div>
                  
                </Form>
            </div>
            </div>
        </>
    )
}

export default SingleTransactionFields;
