import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    requestWithdrawalFailure,
    requestWithdrawalSuccess,
    userWalletFailure,
    userWalletSuccess,
    withdrawalListFailure,
    withdrawalListSuccess,
    withdrawalCancelFailure,
    withdrawalCancelSuccess,
    withdrawalViewFailure,
    withdrawalViewSuccess,
} from "../slices/WithdrawalSlice";


function* withdrawalListAPI(action) {
    yield getCommonSaga({
        apiUrl: "user_withdrawals",
        payload: action.payload,
        successNotify: false,
        success: withdrawalListSuccess,
        failure: withdrawalListFailure,
    });
}

function* withdrawalViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "user_withdrawals/show",
        payload: action.payload,
        successNotify: false,
        success: withdrawalViewSuccess,
        failure: withdrawalViewFailure,
    });
}

function* withdrawalCancelAPI(action) {
    yield getCommonSaga({
        apiUrl: "user_withdrawals/cancel",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: withdrawalCancelSuccess,
        failure: withdrawalCancelFailure,
    });
}

function* userWalletAPI(action) {
    yield getCommonSaga({
        apiUrl: "user_wallet",
        payload: action.payload,
        successNotify: false,
        success: userWalletSuccess,
        failure: userWalletFailure,
    });
}

function* requestWithdrawalAPI(action) {
    yield getCommonSaga({
        apiUrl: "user_withdrawals/store",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: requestWithdrawalSuccess,
        failure: requestWithdrawalFailure,
    });
}

export default function* WithdrawSaga() {
    yield all([
        yield takeLatest('withdraw/withdrawalListStart', withdrawalListAPI),
        yield takeLatest('withdraw/withdrawalViewStart', withdrawalViewAPI),
        yield takeLatest('withdraw/withdrawalCancelStart', withdrawalCancelAPI),
        yield takeLatest('withdraw/userWalletStart', userWalletAPI),
        yield takeLatest('withdraw/requestPayOutStart', requestWithdrawalAPI),

    ]);
}