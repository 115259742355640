import React from 'react';
import { Button } from "react-bootstrap";
import Sidebar from './Sidebar';
import CollapseSidebar from './CollapseSidebar';
import { useSelector } from 'react-redux';


export default function SidebarIndex() {
    const sidebar = useSelector(state => state.common.sidebar);
    const profile = useSelector((state) => state.admin.profile);

    return (
        <>
            <div className="side-wrapped">
                
            {
                sidebar ?
                    <Sidebar profile={profile} />
                    : <CollapseSidebar profile={profile} />
            }
            </div>
        </>

    );
}