import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    invoiceListFailure,
    invoiceListSuccess,
    billListFailure,
    billListSuccess,
    exportBillFailure,
    exportBillSuccess,
    exportInvoiceFailure,
    exportInvoiceSuccess,
} from "../slices/ExpenseSlice";

function* invoiceListAPI(action) {
    yield getCommonSaga({
        apiUrl: "invoice_list",
        payload: action.payload,
        success: invoiceListSuccess,
        failure: invoiceListFailure,
    });
}

function* billListAPI(action) {
    yield getCommonSaga({
        apiUrl: "bill_list",
        payload: action.payload,
        success: billListSuccess,
        failure: billListFailure,
    });
}

function* exportBillAPI(action) {
    yield getCommonSaga({
        apiUrl: "export_bill",
        payload: action.payload,
        success: exportBillSuccess,
        failure: exportBillFailure,
    });
}

function* exportInvoiceAPI(action) {
    yield getCommonSaga({
        apiUrl: "export_invoice",
        payload: action.payload,
        success: exportInvoiceSuccess,
        failure: exportInvoiceFailure,
    });
}

export default function* ExpenseSaga() {
    yield all([
        yield takeLatest('expense/invoiceListStart', invoiceListAPI),
        yield takeLatest('expense/billListStart', billListAPI),
        yield takeLatest('expense/exportBillStart', exportBillAPI),
        yield takeLatest('expense/exportInvoiceStart', exportInvoiceAPI),

    ]);
}