import React, { useState, useEffect } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import ContactCreateModal from "./ContactCreateModal";
import ContactDeleteModal from "./ContactDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { contactsListStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";

const ContactIndex = (props) => {
  
  const [modalShow, deleteModalShow] = React.useState(false);
  const [createmodalShow, createModalShow] = React.useState(false);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.admin.profile);

  const dispatch = useDispatch();
  const contactsList = useSelector((state) => state.accounts.contactsList);

  useEffect(() => {
    dispatch(contactsListStart());
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>Recipients List</h3>
            <div className="payment-table-header-right">
              <Button
                className="efi-primary-btn"
                onClick={() => profile.data.onboarding != 4 ? getErrorNotificationMessage("Please complete onboarding first!") : configuration.get("configData.stylopay_service_status") == 1 ? createModalShow(true) : getErrorNotificationMessage("Service not available, Please try again later!")}
              >
                Create Recipient
              </Button>
            </div>
          </div>
          {contactsList.loading ? (
            <>
              {[...Array(3)].map((_, key) => (
                <Skeleton key={key} height={50} width={"100%"} />
              ))}
            </>
          ) : Object.keys(contactsList.data).length > 0 ? (
            contactsList.data.m_contacts.length > 0 ? (
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    <th>Recipient Id</th>
                    {/* <th>IBAN</th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {contactsList.data.m_contacts.map((item, i) => (
                    <tr key={i}>
                      <td>{item.unique_id}</td>
                      {/* <td>{item.iban}</td> */}
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td className="delete-contact-btn-sec">
                        <Link 
                          onClick={() => profile.data.onboarding != 4 ? getErrorNotificationMessage("Please complete onboarding first!") : configuration.get("configData.stylopay_service_status") == 1 ? deleteModalShow(item) : getErrorNotificationMessage("Service not available, Please try again later!")}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="23"
                            viewBox="0 0 32 32"
                          >
                            <g>
                              <path d="M19.5 23.75a1 1 0 001-1v-10a1 1 0 00-2 0v10a1 1 0 001 1zM12.5 23.75a1 1 0 001-1v-10a1 1 0 00-2 0v10a1 1 0 001 1zM20 4.25a1 1 0 000-2h-8a1 1 0 000 2z"></path>
                              <path d="M5 5.25a1 1 0 000 2h1v18.4c0 2.26 1.84 4.1 4.1 4.1h11.8c2.26 0 4.1-1.84 4.1-4.1V7.25h1a1 1 0 000-2H7zm19 2v18.4a2.1 2.1 0 01-2.1 2.1H10.1a2.1 2.1 0 01-2.1-2.1V7.25z"></path>
                            </g>
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoDataFound />
            )
          ) : null}
        </div>
      </div>
      {modalShow && <ContactDeleteModal
        show={modalShow}
        onHide={() => deleteModalShow(false)}
      />}
      {createModalShow && <ContactCreateModal
        show={createmodalShow}
        onHide={() => createModalShow(false)}
      />}
    </>
  );
};
export default ContactIndex;
