import React, { useState } from "react";
import { Image, Button, Form, Row, Col } from "react-bootstrap";
import Select from 'react-select';

const BillingAddress = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #E7E7E7!important",
            borderRadius: "6px!important",
            boxShadow: "none!important",
            height: "45px",
            display: "flex",
            alignItems: "center",
            alignItemsContent: "center",
            cursor: "pointer",
            fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#111",
            fontSize: "0.9em",
            fontWeight: "300",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#000",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.9em",
            fontWeight: "300",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#32089F!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#111",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#f7f7f7" : "#fff",
                color: "#000",
                "&:hover": {
                    backgroundColor: "#f7f7f7",
                    color: "#000",
                },
            };
        },
    };

    const options = [
        { value: "option 1", label: "Option 1" },
        { value: "option 2", label: "Option 2" },
        { value: "option 3", label: "Option 3" },
        { value: "option 4", label: "Option 4" },
    ]
    return (
        <>
            <div className="full-body-card-wrapped">
                <div className="efi-bank-billing-address-frame mb-3">
                    <div className="efi-bank-billing-address-frame-title">
                        <h3>Billing Address</h3>
                        <div className="efi-bank-title-actions">
                            <Button className="profile-action-btn" >     <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#999"
                                    d="M19.733 20.247h-6.377a.75.75 0 010-1.5h6.377a.75.75 0 010 1.5z"
                                ></path>
                                <path
                                    fill="#000"
                                    fillRule="evenodd"
                                    d="M16.058 11.192l1.227-1.635c.608-.81.864-1.808.721-2.811a3.764 3.764 0 00-1.477-2.497 3.795 3.795 0 00-5.308.756L4.47 14c-2.012 2.683-.222 5.879-.145 6.014a.75.75 0 00.483.357c.058.014.618.138 1.4.138 1.262 0 3.1-.323 4.326-1.957l5.369-7.152a.764.764 0 00.156-.208zm-10.58 7.77c.78.095 2.75.162 3.855-1.311l4.996-6.656-3.664-2.751L5.668 14.9c-1.127 1.504-.507 3.345-.19 4.062zm6.087-11.918l3.665 2.751.855-1.14a2.294 2.294 0 00-.456-3.207 2.298 2.298 0 00-3.209.457z"
                                    clipRule="evenodd"
                                ></path>
                            </svg> <span>Edit</span> </Button>
                            <Button className="efi-primary-btn">
                                Update
                            </Button>
                        </div>
                    </div>
                    <div className="efi-bank-billing-address-fields">
                        <Form className="efi-bank-default-form w-100">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Business Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Business Name"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Door No
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Door No"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="default-form-label">
                                                Pincode
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Pincode"
                                                aria-label="Name"
                                                aria-describedby="text-addon"
                                            />
                                        </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Building Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Building Name"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Country
                                        </Form.Label>
                                        <Select
                                            styles={customStyles}
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="Select Country"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Country
                                        </Form.Label>
                                        <Select
                                            styles={customStyles}
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="Select Country"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="efi-bank-billing-address-frame">
                    <div className="efi-bank-billing-address-frame-title">
                        <h3>Billing Address</h3>
                        <div className="efi-bank-title-actions">
                            <Button className="profile-action-btn" >     <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#999"
                                    d="M19.733 20.247h-6.377a.75.75 0 010-1.5h6.377a.75.75 0 010 1.5z"
                                ></path>
                                <path
                                    fill="#000"
                                    fillRule="evenodd"
                                    d="M16.058 11.192l1.227-1.635c.608-.81.864-1.808.721-2.811a3.764 3.764 0 00-1.477-2.497 3.795 3.795 0 00-5.308.756L4.47 14c-2.012 2.683-.222 5.879-.145 6.014a.75.75 0 00.483.357c.058.014.618.138 1.4.138 1.262 0 3.1-.323 4.326-1.957l5.369-7.152a.764.764 0 00.156-.208zm-10.58 7.77c.78.095 2.75.162 3.855-1.311l4.996-6.656-3.664-2.751L5.668 14.9c-1.127 1.504-.507 3.345-.19 4.062zm6.087-11.918l3.665 2.751.855-1.14a2.294 2.294 0 00-.456-3.207 2.298 2.298 0 00-3.209.457z"
                                    clipRule="evenodd"
                                ></path>
                            </svg> <span>Edit</span> </Button>
                            <Button className="efi-primary-btn">
                                Update
                            </Button>
                        </div>
                    </div>
                    <div className="efi-bank-billing-address-fields">
                        <Form className="efi-bank-default-form w-100">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Business Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Business Name"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Door No
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Door No"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="default-form-label">
                                                Pincode
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Pincode"
                                                aria-label="Name"
                                                aria-describedby="text-addon"
                                            />
                                        </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Building Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Building Name"
                                            aria-label="Name"
                                            aria-describedby="text-addon"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Country
                                        </Form.Label>
                                        <Select
                                            styles={customStyles}
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="Select Country"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Country
                                        </Form.Label>
                                        <Select
                                            styles={customStyles}
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="Select Country"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default BillingAddress;