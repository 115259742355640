import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    dashboardAccountListFailure,
    dashboardAccountListSuccess,
    dashboardChatListFailure,
    dashboardChatListSuccess
} from "../slices/DashboardSlice";

function* dashboardAccountListAPI(action) {
    yield getCommonSaga({
        apiUrl: "account_list",
        payload: action.payload,
        success: dashboardAccountListSuccess,
        failure: dashboardAccountListFailure,
    });
}

function* dashboardChatListAPI(action) {
    yield getCommonSaga({
        apiUrl: "chat_list",
        payload: action.payload,
        success: dashboardChatListSuccess,
        failure: dashboardChatListFailure,
    });
}

export default function* DashboardSaga() {
    yield all([
        yield takeLatest('dashboard/dashboardAccountListStart', dashboardAccountListAPI),
        yield takeLatest('dashboard/dashboardChatListStart', dashboardChatListAPI),
    ]);
}