import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    walletList: getCommonInitialState(),
    walletView: getCommonInitialState(),

};

const WalletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {

        walletListStart: (state) => { state.walletList = getCommonStart() },
        walletListSuccess: (state, action) => { state.walletList = getCommonSuccess(action.payload) },
        walletListFailure: (state, action) => { state.walletList = getCommonFailure(action.payload) },

        walletViewStart: (state) => { state.walletView = getCommonStart() },
        walletViewSuccess: (state, action) => { state.walletView = getCommonSuccess(action.payload) },
        walletViewFailure: (state, action) => { state.walletView = getCommonFailure(action.payload) },

    }
});

export const {
    walletListFailure,
    walletListStart,
    walletListSuccess,
    walletViewFailure,
    walletViewStart,
    walletViewSuccess,

} = WalletSlice.actions;

export default WalletSlice.reducer;