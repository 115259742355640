import React, { useEffect, useState, useRef } from "react";
import { Image, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import * as Yup from "yup";
import "@djthoms/pretty-checkbox";
import { countries } from "countries-list";
import isEqual from "lodash/isEqual";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { businessInfoUpdateStart } from "../../store/slices/BusinessSlice";
import iso from "iso-3166-1";

const BusinessInformation = (props) => {
  const t = useTranslation("onboarding.onboarding_form.business_information");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const profile = useSelector((state) => state.admin.profile);
  const businessInfoUpdate = useSelector(
    (state) => state.business.businessInfoUpdate
  );
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef();
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#dde1e6",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#dde1e6",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const taxIdOptions = [
    { label: "EIN", value: "ein" },
    { label: "TIN", value: "tin" },
    { label: "UEN", value: "uen" },
    { label: "ITN", value: "itn" },
    { label: "BRN", value: "brn" },
    { label: "NPWP", value: "npwp" },
    { label: "IN", value: "in" },
  ];

  const transactionOptions = [
    { label: "Cash Deposit", value: "cash_deposit" },
    { label: "Cash Withdrawal", value: "cash_withdrawal" },
    { label: "Forex", value: "forex" },
    { label: "Loan Payments", value: "loan_payments" },
    { label: "Online Payment", value: "online_payment" },
    { label: "Others", value: "others" },
    { label: "Wire Transfers", value: "wire_transfers" },
  ];

  const fundingOptions = [
    { label: "Debt", value: "debt" },
    { label: "Debt Capital", value: "debt_capital" },
    { label: "Directors' Capital", value: "directors_capital" },
    { label: "Equity Capital", value: "equity_capital" },
    { label: "Others", value: "others" },
    { label: "Term Loan", value: "term_loan" },
    { label: "Venture Funding", value: "venture_funding" },
  ];

  const codeValidationRules = {
    EIN: {
      min: 9,
      max: 9,
      regex: /^\d{9}$/,  // Digits only, length is 9
      message: "Invalid EIN code",
    },
    TIN: {
      min: 15,
      max: 15,
      regex: /^[A-Za-z0-9]{15}$/, // Alphanumeric, length 15
      message: "Invalid TIN code",
    },
    UEN: {
      min: 9,
      max: 10,
      regex: /^\d{9,10}$/, // Digits only, length can be 9 or 10
      message: "Invalid UEN code",
    },
    ITN: {
      min: 13,
      max: 13,
      regex: /^\d{13}$/, // Digits only, length is 13
      message: "Invalid ITN code",
    },
    BRN: {
      min: 8,
      max: 8,
      regex: /^\d{8}$/, // Digits only, length is 8
      message: "Invalid BRN code",
    },
    NPWP: {
      min: 16,
      max: 16,
      regex: /^\d{16}$/, // Digits only, length is 16
      message: "Invalid NPWP code",
    },
    IN: {
      min: 11,
      max: 11,
      regex: /^\d{11}$/, // Digits only, length is 11
      message: "Invalid IN code",
    },
  };
  

  const countryOptions = Object.keys(countries)
    .filter(
      (country) =>
        ![
          "AF",
          "BY",
          "CF",
          "CN",
          "CU",
          "CD",
          "ET",
          "IN",
          "IR",
          "IQ",
          "LY",
          "ML",
          "NI",
          "KP",
          "SO",
          "SS",
          "SD",
          "SY",
          "US",
          "VE",
          "YE",
          "ZW",
        ].includes(country)
    )
    .map((alpha2) => ({
      value: alpha2,
      label: countries[alpha2].name,
    }));

  const validationSchema = Yup.object().shape({
    operational_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_address_1.invalid"))
      .trim(),
    operational_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_address_2.invalid"))
      .trim(),
    operational_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_city.invalid"))
      .trim(),
    operational_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_state.invalid"))
      .trim(),
    operational_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_pincode.invalid"))
      .trim(),
    operational_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_country.invalid"))
      .trim(),
    foundation_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_1.invalid"))
      .trim(),
    foundation_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_address_2.invalid"))
      .trim(),
    foundation_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_city.invalid"))
      .trim(),
    foundation_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_state.invalid"))
      .trim(),
    foundation_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_pincode.invalid"))
      .trim(),
    foundation_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_country.invalid"))
      .trim(),
    tax_id_type: Yup.string().required(t("required")),
    // tax_id_number: Yup.string().required(t("required"))
    tax_id_number: Yup.string().when("tax_id_type", (tax_id_type, schema) => {
      const rule = codeValidationRules[tax_id_type?.toUpperCase()];
      if (rule) {
        const { min, max, regex, message } = rule;
        return Yup.string()
          .required(t("required"))
          .min(min, message)
          .max(max, message)
          .matches(regex, message);
      }
      return schema.required(t("required"));
    }),
    tax_id_issuance_country: Yup.string().required(t("required")),
    foundation_date: Yup.string().required(t("required")),
    tax_id_date_issued: Yup.string().required(t("required")),
    tax_id_date_expiry: Yup.string().required(t("required")),
    purpose_of_account_opening: Yup.string().required(t("required")),
    source_of_funds: Yup.string().required(t("required")),
    // additional_details: Yup.string().required(t("required")),
    company_name: Yup.string().required(t("required")),
    preferred_name: Yup.string().required(t("required")),
  });

  const handleSubmit = (values) => {
    dispatch(businessInfoUpdateStart(values));
  };

  const handleCheckboxChange = () => {
    setDeliverySameAsBilling(!deliverySameAsBilling);
    if (!deliverySameAsBilling) {
      // Copy values from billing address to delivery address fields
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        foundation_primary_address: billingAddress.operational_primary_address,
        foundation_secondary_address:
          billingAddress.operational_secondary_address,
        foundation_country: billingAddress.operational_country,
        foundation_state: billingAddress.operational_state,
        foundation_city: billingAddress.operational_city,
        foundation_zipcode: billingAddress.operational_zipcode,
      });
    }
  };

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      foundation_primary_address: billingValues.operational_primary_address,
      foundation_secondary_address: billingValues.operational_secondary_address,
      foundation_country: billingValues.operational_country,
      foundation_state: billingValues.operational_state,
      foundation_city: billingValues.operational_city,
      foundation_zipcode: billingValues.operational_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  return (
    <>
      <div className="onboarding-auth billing-addres-form">
        <Formik
          initialValues={{
            operational_primary_address:
              profile.data.user_business.operational_address?.primary_address,
            operational_secondary_address:
              profile.data.user_business.operational_address?.secondary_address,
            operational_country:
              profile.data.user_business.operational_address?.country,
            operational_state:
              profile.data.user_business.operational_address?.state,
            operational_city:
              profile.data.user_business.operational_address?.city,
            operational_zipcode:
              profile.data.user_business.operational_address?.zipcode,
            foundation_primary_address:
              profile.data.user_business.foundation_address?.primary_address,
            foundation_secondary_address:
              profile.data.user_business.foundation_address?.secondary_address,
            foundation_country:
              profile.data.user_business.foundation_address?.country,
            foundation_state:
              profile.data.user_business.foundation_address?.state,
            foundation_city:
              profile.data.user_business.foundation_address?.city,
            foundation_zipcode:
              profile.data.user_business.foundation_address?.zipcode,
            tax_id_type: profile.data.user_business.tax_id_type,
            tax_id_number: profile.data.user_business.tax_id_number,
            tax_id_issuance_country:
              profile.data.user_business.tax_id_issuance_country,
            foundation_date: profile.data.user_business.foundation_date,
            tax_id_date_issued: profile.data.user_business.tax_id_date_issued,
            tax_id_date_expiry: profile.data.user_business.tax_id_date_expiry,
            purpose_of_account_opening:
              profile.data.user_business.purpose_of_account_opening,
            source_of_funds: profile.data.user_business.source_of_funds,
            additional_details: profile.data.user_business.additional_details,
            company_name: profile.data.user_business.company_name,
            preferred_name: profile.data.user_business.preferred_name,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
          
        >
          {({ setFieldValue, values, touched, errors }) => (
            <FORM
              className="efi-bank-default-form efi-onboarding-form-after-login w-100"
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("company_name.name")}
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("company_name.placeholder")}
                      aria-describedby="text-addon"
                      name="company_name"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="company_name"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_type.name")}
                    </Form.Label>
                    <Select
                      options={taxIdOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue("tax_id_type", selectedOption.value)
                      }
                      value={taxIdOptions.find(
                        (option) => option.value === values.tax_id_type
                      )}
                      placeholder={t("tax_id_type.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("registered_date.name")}
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date("1900-01-01")}
                          maxDate={new Date()}
                          value={values.foundation_date}
                          onChange={(e) =>
                            setFieldValue("foundation_date", e ? e : "")
                          }
                          placeholder={t("registered_date.placeholder")}
                          width="100%"
                          format="YYYY-MM-DD"
                          id="date-picker-input"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input =
                              document.getElementById("date-picker-input");
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="foundation_date"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_date_issued.name")}
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date("1900-01-01")}
                          maxDate={new Date()}
                          value={values.tax_id_date_issued}
                          onChange={(e) =>
                            setFieldValue("tax_id_date_issued", e ? e : "")
                          }
                          placeholder={t("tax_id_date_issued.placeholder")}
                          width="100%"
                          format="YYYY-MM-DD"
                          id="date-picker-input-1"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input = document.getElementById(
                              "date-picker-input-1"
                            );
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_date_issued"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("purpose_of_account_opening.name")}
                    </Form.Label>
                    <Select
                      options={transactionOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "purpose_of_account_opening",
                          selectedOption.value
                        )
                      }
                      value={transactionOptions.find(
                        (option) =>
                          option.value === values.purpose_of_account_opening
                      )}
                      placeholder={t("purpose_of_account_opening.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="purpose_of_account_opening"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("preferred_name.name")}
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("preferred_name.placeholder")}
                      aria-describedby="text-addon"
                      name="preferred_name"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="preferred_name"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("tax_id_number.name")}
                    </Form.Label>
                    <Field
                      className="form-control"
                      type={values.tax_id_type == "tin" ? "text" : "number"}
                      placeholder={t("tax_id_number.placeholder")}
                      aria-describedby="text-addon"
                      name="tax_id_number"
                    />
                    <ErrorMessage
                      name="tax_id_number"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_issuance_country.name")}
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "tax_id_issuance_country",
                          iso.whereAlpha2(selectedOption.value).alpha3
                        )
                      }
                      value={countryOptions.find(
                        (option) =>
                          option.value === iso.whereAlpha3(values.tax_id_issuance_country).alpha2
                      )}
                      placeholder={t("tax_id_issuance_country.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_issuance_country"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_date_expiry.name")}
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date()}
                          // maxDate={new Date()}
                          value={values.tax_id_date_expiry}
                          onChange={(e) =>
                            setFieldValue("tax_id_date_expiry", e ? e : "")
                          }
                          placeholder={t("tax_id_date_expiry.placeholder")}
                          width="100%"
                          format="YYYY-MM-DD"
                          id="date-picker-input-2"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input = document.getElementById(
                              "date-picker-input-2"
                            );
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_date_expiry"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("source_of_funds.name")}
                    </Form.Label>
                    <Select
                      options={fundingOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue("source_of_funds", selectedOption.value)
                      }
                      value={fundingOptions.find(
                        (option) => option.value === values.source_of_funds
                      )}
                      placeholder={t("source_of_funds.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="source_of_funds"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <div className="onboarding-auth-header resp-marg-zero-1">
                    <h3>{t("operational_address")}</h3>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_address_1.name")}
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("operational_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_primary_address"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="operational_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_address_2.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_secondary_address"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="operational_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_country.name")}
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "operational_country",
                          selectedOption.value
                        );
                        handleAddressChanges();
                      }}
                      value={countryOptions.find(
                        (option) => option.value === values.operational_country
                      )}
                      placeholder={t("operational_country.placeholder")}
                    />
                    <ErrorMessage
                      name="operational_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_state.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_state"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="operational_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_city.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_city"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="operational_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_pincode.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_zipcode"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="operational_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                    <h3> {t("registered_address")}</h3>
                    <Checkbox
                      checked={deliverySameAsBilling}
                      onChange={handleCheckboxChange}
                      icon={
                        <Image
                          src={window.location.origin + "/img/tick-mark.svg"}
                          className="tick-mark-checkbox"
                        />
                      }
                    >
                      {t("same_as_operational_addres")}
                    </Checkbox>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_address_1.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_primary_address"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="foundation_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_address_2.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_secondary_address"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="foundation_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_country.name")}
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "foundation_country",
                          selectedOption.value
                        );
                        handleAddressChanges();
                      }}
                      value={countryOptions.find(
                        (option) => option.value === values.foundation_country
                      )}
                      placeholder={t("registered_country.placeholder")}
                    />
                    <ErrorMessage
                      name="foundation_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_state.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_state"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="foundation_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_city.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_city"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="foundation_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_pincode.name")}
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_zipcode"
                      onInput={handleAddressChanges}
                    />
                    <ErrorMessage
                      name="foundation_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="onboarding-auth-footer">
                <div className="space-tow">
                  <Button
                    className="efi-primary-btn"
                    type="submit"
                    disabled={businessInfoUpdate.buttonDisable}
                    // onClick={() => props.setStep(3)}
                  >
                    {businessInfoUpdate.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("confirm")
                    )}
                  </Button>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BusinessInformation;
