import React from "react";
import { Image, Modal, Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { getSuccessNotificationMessage } from "./NotificationMessage";
import NoDataFound from "./NoDataFound";

const BackupCodeModal = (props) => {

  const generateBackupCodes = useSelector(
    (state) => state.access.generateBackupCodes
  );

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="otp-validation-model"
      backdrop="static"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="backup-code-modal-sec">
          <h3>Backup Codes</h3>
          {generateBackupCodes.data.backup_codes.length > 0 ? (
            <React.Fragment>
              <p>
                <strong>Don’t lose these codes.</strong> Copy them. Each code
                can only be used once. After you’ve used all 10 codes, we’ll
                give you 10 new codes. Keep your codes as safe as your password.
              </p>
              <div className="backup-code-box">
                {generateBackupCodes.data.backup_codes.map((item, key) => (
                  <div key={key} className="backup-code-card">
                    {item}
                  </div>
                ))}
              </div>
              <div className="backup-code-btn-sec">
                <CopyToClipboard
                  text={generateBackupCodes.data.backup_codes.join(", ")}
                >
                  <Button
                    className="profile-action-btn"
                    onClick={() => getSuccessNotificationMessage("Copied!")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#171717"
                        d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                      ></path>
                      <path
                        fill="#171717"
                        d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                        opacity="0.4"
                      ></path>
                    </svg>
                    Copy
                  </Button>
                </CopyToClipboard>
                <Button
                  onClick={() => props.onHide()}
                  className="efi-primary-btn"
                >
                  Close
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <NoDataFound />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BackupCodeModal;
