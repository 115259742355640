import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    sidebar: true,
};

const CommonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {

        //set Sidebar
        toggleSidebar: (state, action) => { state.sidebar = !state.sidebar },


    }
});


export const {
toggleSidebar,



} = CommonSlice.actions;

export default CommonSlice.reducer;