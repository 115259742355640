import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Dropdown } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap'
import AddBeneficiaryModal from './AddBeneficiaryModal';
import ViewBeneficiaryModal from './ViewBeneficiaryModal';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import "./payment.css"

const BeneficiaryTable = () => {
  const beneficiaryList = useSelector((state) => state.payment.beneficiaryList)
  const [beneficiaryAddModal, setBeneficiaryAddModal] = useState(false);
  const [viewBeneficiary, setViewBeneficiary] = useState(false);

  return (
    <>
      {beneficiaryList.loading ? (
        <div className="common-table">
          {[...Array(4)].map((_, key) => (
            <Skeleton key={key} height={50} />
          ))}
        </div>
      ) : (
        <>
          <div className="payment-table-header">
            <h3>Beneficiary</h3>
            <div className="payment-table-header-right">
              <div className="payment-search-bar">
                {Object.keys(beneficiaryList.data).length > 0 && (
                  <InputGroup className="mb-0">
                    <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#8f9899"
                          d="M23.707 22.293l-5.969-5.969a10.016 10.016 0 10-1.414 1.414l5.969 5.969a1 1 0 001.414-1.414zM10 18a8 8 0 118-8 8.009 8.009 0 01-8 8z"
                          data-original="#000000"
                        ></path>
                      </svg>
                    </InputGroup.Text>
                    <Form.Control type="search" placeholder="search" aria-label="search" aria-describedby="search-addon" />
                  </InputGroup>
                )}
              </div>
              <Button className="export-data-btn" onClick={() => setBeneficiaryAddModal(true)}>Add New Beneficiary</Button>
              <AddBeneficiaryModal show={beneficiaryAddModal}
                onHide={() => setBeneficiaryAddModal(false)} />
            </div>
          </div>
          <Table className="common-table" responsive>
            {Object.keys(beneficiaryList.data).length > 0 ? (
              beneficiaryList.data.map((data, i) =>
                <>
                  <thead className="common-table-header">
                    <tr>
                      <th>Name</th>
                      <th>Account Number</th>
                      <th>Account Type</th>
                      <th>Action  </th>
                    </tr>
                  </thead>
                  <tbody className="common-table-body">
                    <tr>
                      <td>Albert</td>
                      <td>12345678972568923</td>
                      <td><div className='green-badge'>Success</div></td>
                      <td>
                        <div className="payment-link-dropdown">
                          <Dropdown>
                            <div className="payment-link-icon-sec">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <circle
                                    cx="458.667"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="256"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="53.333"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                </svg>
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              <Dropdown.Item >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="#8f9899">
                                    <path
                                      d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"
                                      data-original="#000000"
                                    ></path>
                                    <path
                                      d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"
                                      data-original="#000000"
                                    ></path>
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <div onClick={() => setViewBeneficiary(true)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="#8f95b2" data-name="01 align center">
                                      <path
                                        d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 19c-6.307 0-9.25-5.366-10-6.989C2.75 10.366 5.693 5 12 5c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span> View</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Albert</td>
                      <td>12345678972568923</td>
                      <td><div className='green-badge'>Success</div></td>
                      <td>
                        <div className="payment-link-dropdown">
                          <Dropdown>
                            <div className="payment-link-icon-sec">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <circle
                                    cx="458.667"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="256"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="53.333"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                </svg>
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              <Dropdown.Item >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="#8f9899">
                                    <path
                                      d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"
                                      data-original="#000000"
                                    ></path>
                                    <path
                                      d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"
                                      data-original="#000000"
                                    ></path>
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <div onClick={() => setViewBeneficiary(true)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="#8f95b2" data-name="01 align center">
                                      <path
                                        d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 19c-6.307 0-9.25-5.366-10-6.989C2.75 10.366 5.693 5 12 5c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span> View</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Albert</td>
                      <td>12345678972568923</td>
                      <td><div className='purple-badge'>Current</div></td>
                      <td>
                        <div className="payment-link-dropdown">
                          <Dropdown>
                            <div className="payment-link-icon-sec">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <circle
                                    cx="458.667"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="256"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="53.333"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                </svg>
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              <Dropdown.Item >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="#8f9899">
                                    <path
                                      d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"
                                      data-original="#000000"
                                    ></path>
                                    <path
                                      d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"
                                      data-original="#000000"
                                    ></path>
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <div onClick={() => setViewBeneficiary(true)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="#8f95b2" data-name="01 align center">
                                      <path
                                        d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 19c-6.307 0-9.25-5.366-10-6.989C2.75 10.366 5.693 5 12 5c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span> View</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Albert</td>
                      <td>12345678972568923</td>
                      <td><div className='green-badge'>Success</div></td>
                      <td>
                        <div className="payment-link-dropdown">
                          <Dropdown>
                            <div className="payment-link-icon-sec">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <circle
                                    cx="458.667"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="256"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="53.333"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                </svg>
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              <Dropdown.Item >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="#8f9899">
                                    <path
                                      d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"
                                      data-original="#000000"
                                    ></path>
                                    <path
                                      d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"
                                      data-original="#000000"
                                    ></path>
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <div onClick={() => setViewBeneficiary(true)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="#8f95b2" data-name="01 align center">
                                      <path
                                        d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 19c-6.307 0-9.25-5.366-10-6.989C2.75 10.366 5.693 5 12 5c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span> View</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Albert</td>
                      <td>123456789725689000</td>
                      <td><div className='purple-badge'>Current</div></td>
                      <td>
                        <div className="payment-link-dropdown">
                          <Dropdown>
                            <div className="payment-link-icon-sec">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <circle
                                    cx="458.667"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="256"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                  <circle
                                    cx="53.333"
                                    cy="256"
                                    r="53.333"
                                  ></circle>
                                </svg>
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              <Dropdown.Item >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="#8f9899">
                                    <path
                                      d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"
                                      data-original="#000000"
                                    ></path>
                                    <path
                                      d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"
                                      data-original="#000000"
                                    ></path>
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <div onClick={() => setViewBeneficiary(true)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="#8f95b2" data-name="01 align center">
                                      <path
                                        d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 19c-6.307 0-9.25-5.366-10-6.989C2.75 10.366 5.693 5 12 5c6.292 0 9.236 5.343 10 7-.764 1.657-3.708 7-10 7z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span> View</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </>)) : ("no data found")}
          </Table>
        </>
      )}
      <ViewBeneficiaryModal show={viewBeneficiary}
        onHide={() => setViewBeneficiary(false)} />

    </>
  );
};

export default BeneficiaryTable;