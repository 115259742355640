import React, { useEffect } from "react";
import BeneficiaryTable from "./BeneficiaryTable";
import ReceivedPayment from "./ReceivedPayment";
import TransactionHistory from "./TransactionHistory";
import TransferMoney from "./TransferMoney";
import { Image, Nav, Tab } from "react-bootstrap";
import "./payment.css";
import {
  transactionListStart,
  beneficiaryListStart,
  receivedPaymentListStart,
} from "../../store/slices/PaymentSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-multi-lang";
const Paymentmain = () => {
  const t = useTranslation("payments.payment");
  const dispatch = useDispatch();
  const [transferMoneyModal, setTransferMoneyModal] = React.useState(false);

  useEffect(() => {
    dispatch(beneficiaryListStart());
  }, []);

  const handleSubmit = (key) => {
    switch (key) {
      case "beneficiary":
        dispatch(beneficiaryListStart());
        break;
      case "received-payment":
        dispatch(receivedPaymentListStart());
        break;
      case "transaction-history":
        dispatch(transactionListStart());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="full-body-card-wrapped payment-sec">
        <Tab.Container id="left-tabs-example" defaultActiveKey="beneficiary">
          <div className="common-full-card">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link
                  className="beneficiary"
                  eventKey="beneficiary"
                  onClick={(eventKey) => {
                    handleSubmit("beneficiary");
                  }}
                >
                  <div className="navgation-card-icon">
                    <Image
                      src={window.location.origin + "/img/icon/beneficiary.svg"}
                      type="image/svg"
                    />
                  </div>
                  <h4>{t("heading")}</h4>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="received-payment"
                  eventKey="received-payment"
                  onClick={(eventKey) => {
                    handleSubmit("received-payment");
                  }}
                >
                  <div className="navgation-card-icon">
                    <Image
                      src={
                        window.location.origin + "/img/icon/receive-payment.svg"
                      }
                      type="image/svg"
                    />
                  </div>
                  <h4>{t("received_payment")}</h4>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="transfer-money"
                  onClick={() => setTransferMoneyModal(true)}
                >
                  <div className="navgation-card-icon">
                    <Image
                      src={window.location.origin + "/img/icon/money.svg"}
                      type="image/svg"
                    />
                  </div>
                  <h4>{t("transfer_money")}</h4>
                </Nav.Link>
                <TransferMoney
                  show={transferMoneyModal}
                  onHide={() => setTransferMoneyModal(false)}
                />
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="transaction-history"
                  eventKey="transaction-history"
                  onClick={(eventKey) => {
                    handleSubmit("transaction-history");
                  }}
                >
                  <div className="navgation-card-icon">
                    <Image
                      src={window.location.origin + "/img/icon/transaction.svg"}
                      type="image/svg"
                    />
                  </div>
                  <h4>{t("transaction_history")}</h4>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="payment-tab-content">
            <Tab.Content>
              <Tab.Pane eventKey="beneficiary">
                <BeneficiaryTable />
              </Tab.Pane>
              <Tab.Pane eventKey="received-payment">
                <ReceivedPayment />
              </Tab.Pane>
              <Tab.Pane eventKey="transaction-history">
                <TransactionHistory />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Paymentmain;
