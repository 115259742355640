import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    bankAccountsListSuccess,
    bankAccountsListFailure,
    bankAccountsStoreSuccess,
    bankAccountsViewSuccess,
    bankAccountsStoreFailure,
    bankAccountsViewFailure,
    bankAccountsDeleteSuccess,
    bankAccountsDeleteFailure,
} from "../slices/BankAccountsSlice";

function* bankAccountsListAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_bankAccounts",
        payload: action.payload,
        successNotify: false,
        success: bankAccountsListSuccess,
        failure: bankAccountsListFailure,
    });
}

function* bankAccountsStoreAPI(action) {
    const bankAccountsListData = yield select(state => state.accounts.bankAccountsList.data);
    yield getCommonSaga({
        apiUrl: "m_bankAccounts/store",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(bankAccountsStoreSuccess(data));
            if (Object.keys(bankAccountsListData).length > 0)
                store.dispatch(bankAccountsListSuccess({
                m_bankAccounts: [ data.m_contact, ...bankAccountsListData.m_bankAccounts ],
                total_m_bankAccounts: bankAccountsListData.total_m_bankAccounts + 1,
            }))
        },
        failure: bankAccountsStoreFailure,
    });
}

function* bankAccountsViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_bankAccounts/show",
        payload: action.payload,
        successNotify: false,
        success: bankAccountsViewSuccess,
        failure: bankAccountsViewFailure,
    });
}

function* bankAccountsDeleteAPI(action) {
    const bankAccountsListData = yield select(state => state.accounts.bankAccountsList.data);
    yield getCommonSaga({
        apiUrl: "m_bankAccounts/delete",
        payload: action.payload,
        successCallback: true,
        success: data => {
        store.dispatch(bankAccountsDeleteSuccess(data));
        if (Object.keys(bankAccountsListData).length > 0)
            store.dispatch(bankAccountsListSuccess({
            m_bankAccounts: [
                ...bankAccountsListData.m_bankAccounts.filter(item => item.unique_id !== action.payload.m_contact_unique_id),
            ],
            total_m_bankAccounts: bankAccountsListData.total_m_bankAccounts - 1,
            }))
        },
        failure: bankAccountsDeleteFailure,
    });
}


export default function* BankAccountsSaga() {
    yield all([
        yield takeLatest('banks/bankAccountsListStart', bankAccountsListAPI),
        yield takeLatest('banks/bankAccountsStoreStart', bankAccountsStoreAPI),
        yield takeLatest('banks/bankAccountsViewStart', bankAccountsViewAPI),
        yield takeLatest('banks/bankAccountsDeleteStart', bankAccountsDeleteAPI),
    ]);
}