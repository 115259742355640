import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Image, Row, Col } from "react-bootstrap";
import Select from 'react-select';

const WithDrawModal = (props) => {

    const [selectedOption, setSelectedOption] = useState(null);

    const customStyles = {
        ///.....
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          left: "0px",
          borderRadius: "0px",
          overflow: "hidden",
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
          minWidth: 250,
          fontSize: "0.85em",
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            borderRadius: "8px",
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
            backgroundColor: "#555",
          },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
          ...provided,
          backgroundColor: "transparent!important",
          border: "1px solid #E7E7E7!important",
          borderRadius: "8px!important",
          boxShadow: "none!important",
          height: "45px",
          minWidth: "150px",
          cursor: "pointer",
          fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#111",
          fontSize: "0.85em",
          fontWeight: "600",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#32089F",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
          fontSize: "0.9em",
          fontWeight: "600",
        }),
        indicatorContainer: (provided) => ({
          ...provided,
          color: "#32089F!important",
        }),
        indicatorContainer: (provided) => ({
          ...provided,
          fill: "#32089F!important",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          svg: {
            fill: "#111",
          },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected ? "#f7f7f7" : "#fff",
            color: "#000",
          };
        },
      };
    
      const options = [
        { value: "3928392387238", label: "3928392387238" },
        { value: "3782389239028", label: "3782389239028" },
        { value: "2763623773253", label: "2763623773253" },
      ]

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="withdraw-modal"
      show={props.withDraw}
      onHide={props.closeWithDrawModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Withdraw Money
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="withdarw-modal-sec">
            <h3>Wallent Balance: <span>$4983.60</span></h3>
            </div>
            <div className="efi-bank-default-form">
            <Form>
                <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                    <Select
                        className='fillert-drop'
                        styles={customStyles}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        isClearable
                        placeholder={
                            <div className="placeholder-flex">
                              Choose Bank
                            </div>
                          }
                    />
                    </Form.Group>
                    <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                    Min withdraw limit is: $10.00
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                    />
                  </Form.Group>
                </Col>
                </Row>
            </Form>
        </div>
        <div className="withdraw-btn-sec">
            <Button className="efi-primary-btn">
                Submit
            </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WithDrawModal;
