import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    beneficiaryList: getCommonInitialState(),
    receivedPaymentList: getCommonInitialState(),
    transferMoney: getCommonInitialState(),
    transactionList: getCommonInitialState(),
    exportReceivedPayment: getCommonInitialState(),
    exportTransaction: getCommonInitialState(),
    addBeneficiary: getCommonInitialState(),
};

const PaymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {

        beneficiaryListStart: (state) => { state.beneficiaryList = getCommonStart() },
        beneficiaryListSuccess: (state, action) => { state.beneficiaryList = getCommonSuccess(action.payload) },
        beneficiaryListFailure: (state, action) => { state.beneficiaryList = getCommonFailure(action.payload) },

        receivedPaymentListStart: (state) => { state.receivedPaymentList = getCommonStart() },
        receivedPaymentListSuccess: (state, action) => { state.receivedPaymentList = getCommonSuccess(action.payload) },
        receivedPaymentListFailure: (state, action) => { state.receivedPaymentList = getCommonFailure(action.payload) },

        transferMoneyStart: (state) => { state.transferMoney = getCommonStart() },
        transferMoneySuccess: (state, action) => { state.transferMoney = getCommonSuccess(action.payload) },
        transferMoneyFailure: (state, action) => { state.transferMoney = getCommonFailure(action.payload) },

        transactionListStart: (state) => { state.transactionList = getCommonStart() },
        transactionListSuccess: (state, action) => { state.transactionList = getCommonSuccess(action.payload) },
        transactionListFailure: (state, action) => { state.transactionList = getCommonFailure(action.payload) },

        exportReceivedPaymentStart: (state) => { state.exportReceivedPayment = getCommonStart() },
        exportReceivedPaymentSuccess: (state, action) => { state.exportReceivedPayment = getCommonSuccess(action.payload) },
        exportReceivedPaymentFailure: (state, action) => { state.exportReceivedPayment = getCommonFailure(action.payload) },

        exportTransactionStart: (state) => { state.exportTransaction = getCommonStart() },
        exportTransactionSuccess: (state, action) => { state.exportTransaction = getCommonSuccess(action.payload) },
        exportTransactionFailure: (state, action) => { state.exportTransaction = getCommonFailure(action.payload) },

        addBeneficiaryStart: (state) => { state.addBeneficiary = getCommonStart() },
        addBeneficiarySuccess: (state, action) => { state.addBeneficiary = getCommonSuccess(action.payload) },
        addBeneficiaryFailure: (state, action) => { state.addBeneficiary = getCommonFailure(action.payload) },

    }
});

export const {
    beneficiaryListFailure,
    beneficiaryListStart,
    beneficiaryListSuccess,
    receivedPaymentListFailure,
    receivedPaymentListStart,
    receivedPaymentListSuccess,
    transferMoneyFailure,
    transferMoneyStart,
    transferMoneySuccess,
    transactionListFailure,
    transactionListStart,
    transactionListSuccess,
    exportReceivedPaymentFailure,
    exportReceivedPaymentStart,
    exportReceivedPaymentSuccess,
    exportTransactionFailure,
    exportTransactionStart,
    exportTransactionSuccess,
    addBeneficiaryFailure,
    addBeneficiaryStart,
    addBeneficiarySuccess,

} = PaymentSlice.actions;

export default PaymentSlice.reducer;