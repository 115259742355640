import "./App.css";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { apiUrl } from "./Environment";
import configuration from "react-global-configuration";
import PageLoader from "./components/Helper/PageLoader";
import "react-phone-number-input/style.css";
import SomethingWentWrong from "./components/Helper/SomethingWentWrong";

function App() {
  const [configLoading, setConfigLoading] = useState(true);
  const [settingsSuccess, setSettingsSuccess] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(`${apiUrl}get_settings_json`);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
      setSettingsSuccess(true);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      {configLoading ? (
        <PageLoader />
      ) : settingsSuccess ? (
        <Base />
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => fetchConfig()}
        />
      )}
    </Provider>
  );
}

export default App;
