import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import ViewTransactionModal from "../Payment/ViewTransactionHistoryModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { useDispatch, useSelector } from "react-redux";
import "../Payment/payment.css";
import {
    beneficiaryTransactionListStart,
    updateBeneficiaryStatusStart,
} from "../../store/slices/BeneficiariesSlice";
import { Field, Form as FORM, Formik } from "formik";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Select from "react-select";
import {
    getErrorNotificationMessage,
    getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Helper/Pagination";
import { accountsStatementsStart } from "../../store/slices/AccountsSlice";
import { ButtonLoader } from "../Helper/Loader";
import CopyToClipboard from "react-copy-to-clipboard";

const BalanceStatement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const t = useTranslation("beneficiaries.beneficiary_transactions");
    const beneficiaryTransactionList = useSelector((state) => state.beneficiaries.beneficiaryTransactionList);
    const updateBeneficiaryStatus = useSelector((state) => state.beneficiaries.updateBeneficiaryStatus);
    const [viewTransactionHistory, setViewTransactionHistory] = useState(false);

    const profile = useSelector((state) => state.admin.profile);
    const accountsStatements = useSelector(
        (state) => state.accounts.accountsStatements
    );

    // const [startDate, setStartDate] = useState(new Date("2024/04/08"));
    // const [endDate, setEndDate] = useState(new Date("2024/02/10"));

    const [searchParams] = useSearchParams();

    const [skipRender, setSkipRender] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [filterStatus, setFilterStatus] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [typeStatus, setTypeStatus] = useState(false);
    const [filter, setFilter] = useState({
        search_key: searchParams.get("search_key")
            ? searchParams.get("search_key")
            : "",
        status: "",
    });
    const [close, setClose] = useState(false);
    const [page, setPage] = useState(
        searchParams.get("page") ? searchParams.get("page") : 1
    );
    const calenderRefs = useRef(null);
    const formRef = useRef(null);
    const [dateValues, setDateValues] = useState({
        from_date: "",
        to_date: "",
    });
    const [value, setValue] = useState([null, null]);

    const type_options = [
        { value: "1", label: "Initiated" },
        { value: "2", label: "Processing" },
        { value: "3", label: "Completed" },
        { value: "4", label: "Failed" },
    ];

    useEffect(() => {
        if (!profile.loading && Object.keys(profile.data).length > 0) {
            if (profile.data.onboarding >= 4) {
                dispatch(
                    beneficiaryTransactionListStart({
                        ...filter,
                        skip: 12 * (page - 1),
                        take: 12,
                    })
                );
            } else {
                getErrorNotificationMessage("Please complete the onboarding to continue.")
            }
        }
    }, [profile, filter, page]);

    // const handleExport = () => {
    //   dispatch(
    //     accountsStatementsStart({
    //       ...filter,
    //       from_date: dateValues.from_date
    //         ?.toString()
    //         .split("-")
    //         .reverse()
    //         .join("-"),
    //       to_date: dateValues.to_date?.toString().split("-").reverse().join("-"),
    //     })
    //   );
    // };

    const getStatusClass = (status) => {
        switch (status) {
            case 1:
                return "payment-initiated";
            case 2:
                return "payment-processing";
            case 3:
                return "payment-completed";
            case 4:
                return "payment-failed";
            default:
                return "payment-initiated";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return "#5C9EEB";
            case 2:
                return "#FFCE22";
            case 3:
                return "#197E23";
            case 4:
                return "#FF8A00";
            default:
                return "#5C9EEB";
        }
    };

    const handlePageClick = ({ selected }) => {
        let params = searchParams.get("search_key")
            ? `search_key=${searchParams.get("search_key")}&`
            : "";
        navigate(`/beneficiary-transactions?${params}page=${selected + 1}`);
    };

    useEffect(() => {
        if (!skipRender) {
            setPage(searchParams.get("page") ? searchParams.get("page") : 1);
            setFilter({
                ...filter,
                search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                from_date: dateValues.from_date,
                to_date: dateValues.to_date,
            });
        }
        setSkipRender(false);
    }, [searchParams.get("page"), searchParams.get("search_key")]);

    useEffect(() => {
        if (
            !skipRender &&
            !accountsStatements.buttonDisable &&
            Object.keys(accountsStatements.data).length > 0
        ) {
            accountsStatements.data?.url &&
                window.open(accountsStatements.data.url, "_blank");
        }
        setSkipRender(false);
    }, [accountsStatements]);

    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #E7E7E7!important",
            borderRadius: "8px!important",
            boxShadow: "none!important",
            height: "45px",
            minWidth: "180px",
            cursor: "pointer",
            fontSize: "0.95em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#878E96",
            fontSize: "0.95em",
            fontWeight: "400",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#0194ff",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.95em",
            fontWeight: "600",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#32089F!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#878E96",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#f7f7f7" : "#fff",
                color: "#000",
                "&:hover": {
                    backgroundColor: "#f7f7f7",
                    color: "#000",
                },
            };
        },
    };

    const validationSchema = Yup.object().shape({
        search_key: Yup.string()
            .required(t("required"))
            .matches(/^(?=\S*$)/, t("white_space")),
    });

    const handleDateChange = (values) => {
        if (values) {
            setValue(values);
            formRef?.current?.setFieldValue(
                "from_date",
                values && values.length > 0
                    ? JSON.stringify(new Date(values[0])).slice(1, 11)
                    : ""
            );
            formRef?.current?.setFieldValue(
                "to_date",
                values && values.length > 1
                    ? JSON.stringify(new Date(values[1])).slice(1, 11)
                    : ""
            );
            setDateValues({
                from_date:
                    values.length > 0
                        ? JSON.stringify(new Date(values[0])).slice(1, 11)
                        : "",
                to_date:
                    values.length > 1
                        ? JSON.stringify(new Date(values[1])).slice(1, 11)
                        : "",
            });
        } else {
            setDateValues({
                from_date: "",
                to_date: "",
            });
            formRef?.current?.setFieldValue("from_date", "");
            formRef?.current?.setFieldValue("to_date", "");
            calenderRefs.current.closeCalendar();
            setFilter({
                ...filter,
                skip: 12 * (page - 1),
                take: 12,
                from_date: "",
                to_date: "",
            });
        }
    };
    return (
        <>
            <div className="full-body-card-wrapped">
           
                <div className="common-full-card">
                <div className="balance-statement-box">
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <div className="balance-statement-card">
                            <div className="balance-label">Currency</div>
                            <Select
                                options={type_options}
                                className="select-payout"
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                value={typeStatus || null}
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setFilter({
                                            ...filter,
                                            status: selectedOption.value,
                                        });
                                        setTypeStatus(selectedOption);
                                        navigate(
                                            filter.search_key
                                                ? `/beneficiary-transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                                                : `/beneficiary-transactions?status=${selectedOption.value}`
                                        );
                                    } else {
                                        const newFilter = { ...filter };
                                        delete newFilter.status;
                                        setFilter(newFilter);
                                        navigate(
                                            filter.search_key
                                                ? `/beneficiary-transactions?search_key=${filter.search_key}`
                                                : "/beneficiary-transactions"
                                        );
                                        setTypeStatus(null);
                                    }
                                }}
                                placeholder={
                                    <div className="placeholder-flex">{t("status")}</div>
                                }
                            />
                            <div className="bal-single-space"></div>
<div className="balance-statement-card-info">
    <h3>Balance: <span>23,234 $</span></h3>
</div>
                        </div>
                    </Col>
                </Row>
                        </div>
                        <div className="single-space"></div>                    <div className="efi-feature-header-frame layout-between">
                        <div className="efi-feature-transation-title">
                            <h2>
                                Balance & Statement
                            </h2>
                        </div>
                        <div className="efi-feature-transation-search-frame">
                            <Select
                                options={type_options}
                                className="select-payout"
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                value={typeStatus || null}
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setFilter({
                                            ...filter,
                                            status: selectedOption.value,
                                        });
                                        setTypeStatus(selectedOption);
                                        navigate(
                                            filter.search_key
                                                ? `/beneficiary-transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                                                : `/beneficiary-transactions?status=${selectedOption.value}`
                                        );
                                    } else {
                                        const newFilter = { ...filter };
                                        delete newFilter.status;
                                        setFilter(newFilter);
                                        navigate(
                                            filter.search_key
                                                ? `/beneficiary-transactions?search_key=${filter.search_key}`
                                                : "/beneficiary-transactions"
                                        );
                                        setTypeStatus(null);
                                    }
                                }}
                                placeholder={
                                    <div className="placeholder-flex">{t("status")}</div>
                                }
                            />
                            <div className="header-search">
                                <Formik
                                    initialValues={{
                                        search_key: searchParams.get("search_key")
                                            ? searchParams.get("search_key")
                                            : "",
                                        sort_by: searchParams.get("sort_by")
                                            ? searchParams.get("sort_by")
                                            : filter.sort_by,
                                        from_date: "",
                                        to_date: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(val) => {
                                        setFilter({
                                            ...filter,
                                            search_key: val.search_key,
                                            from_date: dateValues.from_date,
                                            to_date: dateValues.to_date,
                                        });

                                        navigate(
                                            searchParams.get("sort_by")
                                                ? `/beneficiary-transactions?search_key=${val.search_key
                                                }&sort_by=${searchParams.get("sort_by")}`
                                                : `/beneficiary-transactions?search_key=${val.search_key}`,
                                            { state: { value: value } }
                                        );
                                    }}
                                    innerRef={formRef}
                                >
                                    {({ values, setFieldValue, resetForm }) => (
                                        <FORM className="form">
                                            <InputGroup
                                                className={`d-flex date-sec mb-0 efi-data-input ${close ? "date-close" : ""
                                                    }`}
                                            >
                                                <DatePicker
                                                    value={value}
                                                    style={{ padding: "1em" }}
                                                    onChange={(date) => {
                                                        handleDateChange(date);
                                                    }}
                                                    range
                                                    numberOfMonths={2}
                                                    ref={calenderRefs}
                                                    format="DD-MM-YYYY"
                                                    maxDate={
                                                        new Date(new Date().setHours(23, 59, 59, 999))
                                                    }
                                                    id="date-picker-input"
                                                    placeholder={t("date.date_range")}
                                                    // render={<InputIcon/>}
                                                    editable={false}
                                                    onOpenPickNewDate={false}
                                                >
                                                    <div
                                                        className="d-flex justify-content-end gap-2"
                                                        style={{ padding: "1em" }}
                                                    >
                                                        <Button
                                                            className="efi-datePicker-btn-cancel"
                                                            onClick={() => {
                                                                calenderRefs.current.closeCalendar();
                                                                setDateValues({
                                                                    from_date: "",
                                                                    to_date: "",
                                                                });
                                                                setValue([null, null]);
                                                                setClose(false);
                                                                // setFilter({
                                                                //   ...filter,
                                                                //   from_date: "",
                                                                //   to_date: "",
                                                                // });
                                                            }}
                                                        >
                                                            {t("close")}
                                                        </Button>
                                                        <Button
                                                            className="efi-datePicker-btn"
                                                            onClick={() => {
                                                                if (values.from_date || values.to_date) {
                                                                    setFilter({
                                                                        ...filter,
                                                                        skip: 12 * (page - 1),
                                                                        take: 12,
                                                                        from_date: dateValues.from_date
                                                                            .toString()
                                                                            .split("-")
                                                                            .reverse()
                                                                            .join("-"),
                                                                        to_date: dateValues.to_date
                                                                            .toString()
                                                                            .split("-")
                                                                            .reverse()
                                                                            .join("-"),
                                                                    });
                                                                    calenderRefs.current.closeCalendar();
                                                                    setClose(true);
                                                                } else
                                                                    getErrorNotificationMessage(
                                                                        t("date.select_date")
                                                                    );
                                                            }}
                                                        >
                                                            {t("apply")}
                                                        </Button>
                                                    </div>
                                                </DatePicker>
                                                {close ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="search-btn date-cancel"
                                                            onClick={() => {
                                                                // setValue([null , new Date()]);
                                                                setDateValues({
                                                                    from_date: "",
                                                                    to_date: "",
                                                                });
                                                                setValue([null, null]);
                                                                setClose(false);
                                                                setFilter({
                                                                    ...filter,
                                                                    from_date: "",
                                                                    to_date: "",
                                                                });
                                                            }}
                                                        >
                                                            <svg
                                                                height="24"
                                                                width="24"
                                                                viewBox="0 0 20 20"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                class="css-tj5bde-Svg"
                                                            >
                                                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                            </svg>
                                                        </button>
                                                    </>
                                                ) : (
                                                    <InputGroup.Text
                                                        onClick={() => {
                                                            const input =
                                                                document.getElementById("date-picker-input");
                                                            if (input) {
                                                                input.focus();
                                                            }
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            fill="none"
                                                            viewBox="0 0 18 18"
                                                        >
                                                            <g clipPath="url(#clip0_47_6)">
                                                                <path
                                                                    fill="#8F9899"
                                                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                                                ></path>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_47_6">
                                                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </InputGroup.Text>
                                                )}
                                            </InputGroup>
                                            <div className="efi-transation-search">
                                                <InputGroup className="mb-0">
                                                    <Field
                                                        // placeholder={t("search_placeholder")}
                                                        placeholder={t("search")}
                                                        type="text"
                                                        className="form-control trans-form-control"
                                                        name="search_key"
                                                        onKeyPress={(event) => {
                                                            if (event.key === "Enter") {
                                                                event.preventDefault();
                                                                formRef.current.handleSubmit();
                                                            }
                                                        }}
                                                    />
                                                    {searchParams.get("search_key") ? (
                                                        <InputGroup.Text id="basic-addon1">
                                                            <Button
                                                                className="search-btn"
                                                                onClick={() => {
                                                                    setFieldValue("search_key", "");
                                                                    setFilter({
                                                                        ...filter,
                                                                        search_key: "",
                                                                        from_date: dateValues.from_date,
                                                                        to_date: dateValues.to_date,
                                                                    });
                                                                    navigate(
                                                                        searchParams.get("sort_by")
                                                                            ? `/beneficiary-transactions?sort_by=${searchParams.get(
                                                                                "sort_by"
                                                                            )}`
                                                                            : `/beneficiary-transactions`
                                                                    );
                                                                }}
                                                            >
                                                                <svg
                                                                    height="20"
                                                                    width="20"
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                                                >
                                                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                                </svg>
                                                            </Button>
                                                        </InputGroup.Text>
                                                    ) : (
                                                        <InputGroup.Text id="basic-addon1">
                                                            <Button className="search-btn" type="submit">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#333"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                                                >
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                        fill="none"
                                                                    />
                                                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                                                    <path d="M21 21l-6 -6" />
                                                                </svg>
                                                            </Button>
                                                        </InputGroup.Text>
                                                    )}
                                                </InputGroup>
                                            </div>
                                        </FORM>
                                    )}
                                </Formik>
                            </div>
                            <Button className="efi-primary-btn">Download</Button>
                        </div>
                    </div>
                    {beneficiaryTransactionList.buttonDisable ?
                        [...Array(6)].map((i, key) => <Skeleton key={key} height={65} />)
                        : Object.keys(beneficiaryTransactionList.data).length <= 0
                            ?
                            <>
                                <Table className="common-table" responsive>
                                    <thead className="common-table-header">
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Running Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody className="common-table-body">
                                        <tr>
                                            <td>12 - 12 - 2012</td>
                                            <td>
                                                Transfer to donAccount(#dsdc123)
                                            </td>
                                            <td>12,344</td>
                                            <td>123</td>
                                            <td>
                                                12,93,498
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12 - 12 - 2012</td>
                                            <td>
                                                Transfer to donAccount(#dsdc123)
                                            </td>
                                            <td>12,344</td>
                                            <td>123</td>
                                            <td>
                                                12,93,498
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12 - 12 - 2012</td>
                                            <td>
                                                Transfer to donAccount(#dsdc123)
                                            </td>
                                            <td>12,344</td>
                                            <td>123</td>
                                            <td>
                                                12,93,498
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12 - 12 - 2012</td>
                                            <td>
                                                Transfer to donAccount(#dsdc123)
                                            </td>
                                            <td>12,344</td>
                                            <td>123</td>
                                            <td>
                                                12,93,498
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12 - 12 - 2012</td>
                                            <td>
                                                Transfer to donAccount(#dsdc123)
                                            </td>
                                            <td>12,344</td>
                                            <td>123</td>
                                            <td>
                                                12,93,498
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {beneficiaryTransactionList.data.total_beneficiary_transactions > 12 ? (
                                    <div className="new-billings-pagination-sec">
                                        <Pagination
                                            page={page}
                                            pageCount={Math.ceil(
                                                beneficiaryTransactionList.data.total_beneficiary_transactions / 12
                                            )}
                                            handlePageClick={handlePageClick}
                                        />
                                    </div>
                                ) : null}
                            </>
                            :
                            <NoDataFound />
                    }
                </div>
            </div>
        </>
    )
}

export default BalanceStatement;
