import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    teamMemberList: getCommonInitialState(),
    roleAdminList: getCommonInitialState(),
    roleManagerList: getCommonInitialState(),
    roleFinanceList: getCommonInitialState(),
    roleEmployerList: getCommonInitialState(),
    addMember: getCommonInitialState(),
    changeRole: getCommonInitialState(),
    generateBackupCodes: getCommonInitialState(),
};

const UserAccessSlice = createSlice({
    name: "access",
    initialState,
    reducers: {

        teamMemberListStart: (state) => { state.teamMemberList = getCommonStart() },
        teamMemberListSuccess: (state, action) => { state.teamMemberList = getCommonSuccess(action.payload) },
        teamMemberListFailure: (state, action) => { state.teamMemberList = getCommonFailure(action.payload) },

        roleAdminListStart: (state) => { state.roleAdminList = getCommonStart() },
        roleAdminListSuccess: (state, action) => { state.roleAdminList = getCommonSuccess(action.payload) },
        roleAdminListFailure: (state, action) => { state.roleAdminList = getCommonFailure(action.payload) },

        roleManagerListStart: (state) => { state.roleManagerList = getCommonStart() },
        roleManagerListSuccess: (state, action) => { state.roleManagerList = getCommonSuccess(action.payload) },
        roleManagerListFailure: (state, action) => { state.roleManagerList = getCommonFailure(action.payload) },

        roleFinanceListStart: (state) => { state.roleFinanceList = getCommonStart() },
        roleFinanceListSuccess: (state, action) => { state.roleFinanceList = getCommonSuccess(action.payload) },
        roleFinanceListFailure: (state, action) => { state.roleFinanceList = getCommonFailure(action.payload) },

        roleEmployerListStart: (state) => { state.roleEmployerList = getCommonStart() },
        roleEmployerListSuccess: (state, action) => { state.roleEmployerList = getCommonSuccess(action.payload) },
        roleEmployerListFailure: (state, action) => { state.roleEmployerList = getCommonFailure(action.payload) },

        addMemberStart: (state) => { state.addMember = getCommonStart() },
        addMemberSuccess: (state, action) => { state.addMember = getCommonSuccess(action.payload) },
        addMemberFailure: (state, action) => { state.addMember = getCommonFailure(action.payload) },

        changeRoleStart: (state) => { state.changeRole = getCommonStart() },
        changeRoleSuccess: (state, action) => { state.changeRole = getCommonSuccess(action.payload) },
        changeRoleFailure: (state, action) => { state.changeRole = getCommonFailure(action.payload) },

        generateBackupCodesStart: (state) => { state.generateBackupCodes = getCommonStart() },
        generateBackupCodesSuccess: (state, action) => { state.generateBackupCodes = getCommonSuccess(action.payload) },
        generateBackupCodesFailure: (state, action) => { state.generateBackupCodes = getCommonFailure(action.payload) },


    }
});

export const {
    teamMemberListFailure,
    teamMemberListStart,
    teamMemberListSuccess,
    roleAdminListFailure,
    roleAdminListStart,
    roleAdminListSuccess,
    roleManagerListFailure,
    roleManagerListStart,
    roleManagerListSuccess,
    roleFinanceListFailure,
    roleFinanceListStart,
    roleFinanceListSuccess,
    roleEmployerListFailure,
    roleEmployerListStart,
    roleEmployerListSuccess,
    addMemberFailure,
    addMemberStart,
    addMemberSuccess,
    changeRoleFailure,
    changeRoleStart,
    changeRoleSuccess,
    generateBackupCodesStart,
    generateBackupCodesSuccess,
    generateBackupCodesFailure,
} = UserAccessSlice.actions;

export default UserAccessSlice.reducer;