import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  cardsListFailure,
  cardsListSuccess,
  cardViewFailure,
  cardViewSuccess,
  virtualCardCreateFailure,
  virtualCardCreateSuccess,
  physicalCardAssignFailure,
  physicalCardAssignSuccess,
  activateCardFailure,
  activateCardSuccess,
  setPinFailure,
  setPinSuccess,
  cardBlockUnblockFailure,
  cardBlockUnblockSuccess,
} from "../slices/CardSlice";

function* cardsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "m_card/list",
    payload: action.payload,
    successNotify: false,
    success: cardsListSuccess,
    failure: cardsListFailure,
  });
}

function* cardViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "m_card/show",
    payload: action.payload,
    successNotify: false,
    success: cardViewSuccess,
    failure: cardViewFailure,
  });
}

function* virtualCardCreateAPI(action) {
  const cardsListData = yield select(state => state.card.cardsList.data);
  yield getCommonSaga({
    apiUrl: "m_card/create_virtual_card",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(virtualCardCreateSuccess(data));
      if (Object.keys(cardsListData).length > 0)
          store.dispatch(cardsListSuccess({
          m_cards: [ data.m_card, ...cardsListData.m_cards ],
      }))
    },
    failure: virtualCardCreateFailure,
  });
}

function* physicalCardAssignAPI(action) {
  const cardsListData = yield select(state => state.card.cardsList.data);
  yield getCommonSaga({
    apiUrl: "m_card/assign_physical_card",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(physicalCardAssignSuccess(data));
      if (Object.keys(cardsListData).length > 0)
          store.dispatch(cardsListSuccess({
          m_cards: [ data.m_card, ...cardsListData.m_cards ],
      }))
    },
    failure: physicalCardAssignFailure,
  });
}

function* activateCardAPI(action) {
  yield getCommonSaga({
    apiUrl: "activate_card",
    payload: action.payload,
    successNotify: false,
    success: activateCardSuccess,
    failure: activateCardFailure,
  });
}

function* setPinAPI(action) {
  yield getCommonSaga({
    apiUrl: "set_pin",
    payload: action.payload,
    successNotify: false,
    success: setPinSuccess,
    failure: setPinFailure,
  });
}

function* cardBlockUnblockAPI(action) {
  const cardsListData = yield select(state => state.card.cardsList.data);
  yield getCommonSaga({
    apiUrl: "m_card/block_unblock",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(cardBlockUnblockSuccess(data));
      if (Object.keys(cardsListData).length > 0)
          store.dispatch(cardsListSuccess({
          m_cards: [...cardsListData.m_cards.map((item) => item.unique_id == action.payload.m_card_unique_id ? data.m_card : item)],
      }))
    },
    failure: cardBlockUnblockFailure,
  });
}

export default function* CardSaga() {
  yield all([
    yield takeLatest("card/cardsListStart", cardsListAPI),
    yield takeLatest("card/cardViewStart", cardViewAPI),
    yield takeLatest("card/virtualCardCreateStart", virtualCardCreateAPI),
    yield takeLatest("card/physicalCardAssignStart", physicalCardAssignAPI),
    yield takeLatest("card/activateCardStart", activateCardAPI),
    yield takeLatest("card/setPinStart", setPinAPI),
    yield takeLatest("card/cardBlockUnblockStart", cardBlockUnblockAPI),
  ]);
}
