import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  beneficiaryListFailure,
  beneficiaryListSuccess,
  receivedPaymentListFailure,
  receivedPaymentListSuccess,
  transferMoneyFailure,
  transferMoneySuccess,
  transactionListFailure,
  transactionListSuccess,
  exportReceivedPaymentFailure,
  exportReceivedPaymentSuccess,
  exportTransactionFailure,
  exportTransactionSuccess,
  addBeneficiaryFailure,
  addBeneficiarySuccess,
} from "../slices/PaymentSlice";
import { accountsViewSuccess } from "../slices/AccountsSlice";

function* beneficiaryListAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiary_list",
    payload: action.payload,
    success: beneficiaryListSuccess,
    failure: beneficiaryListFailure,
  });
}

function* receivedPaymentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "received_payment_list",
    payload: action.payload,
    success: receivedPaymentListSuccess,
    failure: receivedPaymentListFailure,
  });
}

function* transferMoneyAPI(action) {
  const transactionListData = yield select((state) => state.payment.transactionList.data);
  const accountsViewData = yield select((state) => state.accounts.accountsView.data);
  yield getCommonSaga({
    apiUrl: "m_transactions/store",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(transferMoneySuccess(data));
      if (Object.keys(transactionListData).length > 0)
        store.dispatch(
          transactionListSuccess({
            m_transactions: [data.m_transaction,...transactionListData.m_transactions],
            total_m_transactions: transactionListData.total_m_transactions - 1,
          })
        );
        if (Object.keys(accountsViewData).length > 0)
        store.dispatch(
          accountsViewSuccess({
            ...accountsViewData,
            m_account_balance: data.m_account_balance,
          })
        );
    },
    failure: transferMoneyFailure,
  });
}

function* transactionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "m_transactions",
    payload: action.payload,
    successNotify: false,
    success: transactionListSuccess,
    failure: transactionListFailure,
  });
}

function* exportReceivedPaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "export_received_payment",
    payload: action.payload,
    success: exportReceivedPaymentSuccess,
    failure: exportReceivedPaymentFailure,
  });
}

function* exportTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "export_transaction",
    payload: action.payload,
    success: exportTransactionSuccess,
    failure: exportTransactionFailure,
  });
}

function* addBeneficiaryAPI(action) {
  yield getCommonSaga({
    apiUrl: "add_beneficiary",
    payload: action.payload,
    success: addBeneficiarySuccess,
    failure: addBeneficiaryFailure,
  });
}

export default function* PaymentSaga() {
  yield all([
    yield takeLatest("payment/beneficiaryListStart", beneficiaryListAPI),
    yield takeLatest(
      "payment/receivedPaymentListStart",
      receivedPaymentListAPI
    ),
    yield takeLatest("payment/transferMoneyStart", transferMoneyAPI),
    yield takeLatest("payment/transactionListStart", transactionListAPI),
    yield takeLatest(
      "payment/exportReceivedPaymentStart",
      exportReceivedPaymentAPI
    ),
    yield takeLatest("payment/exportTransactionStart", exportTransactionAPI),
    yield takeLatest("payment/addBeneficiaryStart", addBeneficiaryAPI),
  ]);
}
