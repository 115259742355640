import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const EmptyHeader = (props) => {
  return (
    <>
      <header>
		<Navbar expand="lg">
		<Container fluid>
			<div className="admin-ui-title-sec">
			<div className="admin-user-details">
				<Link to="/">
				<Image
					style={{ width: "10em" }}
					className="admin-user-img"
					src={window.location.origin + "/img/efi-bank-logo.png"}
				/>
				</Link>
			</div>
			</div>
		</Container>
		</Navbar>
      </header>
    </>
  );
};

export default EmptyHeader;
