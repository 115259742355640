import { React, useState } from "react";
import { Table, Button } from "react-bootstrap";

const WithDrawHistory = (props) => {
  return (
    <>
      <Table className="common-table" responsive>
        <thead className="common-table-header">
          <tr>
            <th>Date</th>
            <th>Transaction Id</th>
            <th>Credit/Debit</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="common-table-body">
          <tr>
            <td>29-Nov-2023 </td>
            <td>#4589445</td>
            <td>
              <div className="green-badge">Credit</div>
            </td>
            <td>500</td>
            <td>
              <div className="green-badge">Success</div>
            </td>
            <td>
              <Button className="table-action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g fill="#6359e9">
                    <path
                      d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </Button>
            </td>
          </tr>
          <tr>
            <td>29-Nov-2023</td>
            <td>#4589445</td>
            <td>
              <div className="green-badge">Credit</div>
            </td>
            <td>500</td>
            <td>
              <div className="green-badge">Success</div>
            </td>
            <td>
              <Button className="table-action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g fill="#6359e9">
                    <path
                      d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </Button>
            </td>
          </tr>
          <tr>
            <td>29-Nov-2023 </td>
            <td>#4589445</td>
            <td>
              <div className="red-badge">Debit</div>
            </td>
            <td>500</td>
            <td>
              <div className="green-badge">Success</div>
            </td>
            <td>
              <Button className="table-action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g fill="#6359e9">
                    <path
                      d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </Button>
            </td>
          </tr>
          <tr>
            <td>29-Nov-2023 </td>
            <td>#4589445</td>
            <td>
              <div className="red-badge">Debit</div>
            </td>
            <td>500</td>
            <td>
              <div className="green-badge">Success</div>
            </td>
            <td>
              <Button className="table-action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g fill="#6359e9">
                    <path
                      d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </Button>
            </td>
          </tr>
          <tr>
            <td>29-Nov-2023 </td>
            <td>#4589445</td>
            <td>
              <div className="green-badge">Credit</div>
            </td>
            <td>500</td>
            <td>
              <div className="green-badge">Success</div>
            </td>
            <td>
              <Button className="table-action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g fill="#6359e9">
                    <path
                      d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default WithDrawHistory;
