import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    invoiceList: getCommonInitialState(),
    billList: getCommonInitialState(),
    exportBill: getCommonInitialState(),
    exportInvoice: getCommonInitialState(),
};

const ExpenseSlice = createSlice({
    name: "expense",
    initialState,
    reducers: {

        invoiceListStart: (state) => { state.invoiceList = getCommonStart() },
        invoiceListSuccess: (state, action) => { state.invoiceList = getCommonSuccess(action.payload) },
        invoiceListFailure: (state, action) => { state.invoiceList = getCommonFailure(action.payload) },

        billListStart: (state) => { state.billList = getCommonStart() },
        billListSuccess: (state, action) => { state.billList = getCommonSuccess(action.payload) },
        billListFailure: (state, action) => { state.billList = getCommonFailure(action.payload) },

        exportBillStart: (state) => { state.exportBill = getCommonStart() },
        exportBillSuccess: (state, action) => { state.exportBill = getCommonSuccess(action.payload) },
        exportBillFailure: (state, action) => { state.exportBill = getCommonFailure(action.payload) },

        exportInvoiceStart: (state) => { state.exportInvoice = getCommonStart() },
        exportInvoiceSuccess: (state, action) => { state.exportInvoice = getCommonSuccess(action.payload) },
        exportInvoiceFailure: (state, action) => { state.exportInvoice = getCommonFailure(action.payload) },

    }
});

export const {
    invoiceListFailure,
    invoiceListStart,
    invoiceListSuccess,
    billListFailure,
    billListStart,
    billListSuccess,
    exportBillFailure,
    exportBillStart,
    exportBillSuccess,
    exportInvoiceFailure,
    exportInvoiceStart,
    exportInvoiceSuccess,
} = ExpenseSlice.actions;

export default ExpenseSlice.reducer;