import React from 'react';
import { Tab, Nav, Image, Table } from 'react-bootstrap';
import AdminList from './AdminList';
import ManagerList from './ManagerList';
import FinanceList from './FinanceList';
import EmployerList from './EmployerList';
import "../userAccess.css"


const RolesAndResponsibility = () => {

  return (
    <div className="user-access-team">
      <div className="payment-table-header">
        <h3>Roles & Responsibility</h3>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="default">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="admin">
              <div className="user-access-team-sec">
                <div className="user-access-icon">
                  <Image
                    className="account-details-icon"
                    src={
                      window.location.origin + "/img/icon/admin-nav-icon.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="user-access-info">
                  <h4>Admin</h4>
                  <p>Users-01</p>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="manager">
              <div className="user-access-team-sec">
                <div className="user-access-icon">
                  <Image
                    className="account-details-icon"
                    src={
                      window.location.origin + "/img/icon/manager.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="user-access-info">
                  <h4>Manager</h4>
                  <p>Users-01</p>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="finance">
              <div className="user-access-team-sec">
                <div className="user-access-icon">
                  <Image
                    className="account-details-icon"
                    src={
                      window.location.origin + "/img/icon/finance.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="user-access-info">
                  <h4>Finance</h4>
                  <p>Users-01</p>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="employers">
              <div className="user-access-team-sec">
                <div className="user-access-icon">
                  <Image
                    className="account-details-icon"
                    src={
                      window.location.origin + "/img/icon/employee.svg"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="user-access-info">
                  <h4>Employer</h4>
                  <p>Users-01</p>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>

        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="default">
            <Table className='common-table received-payment' responsive>
              <thead className='common-table-header'>
                <tr>
                  <th>Permissions</th>
                  <th>Admin</th>
                  <th>Manager</th>
                  <th>Finance</th>
                  <th>Employers</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>User Access</td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                </tr>
                <tr>
                  <td>Transactions</td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                </tr>
                <tr>
                  <td>Accounts</td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                </tr>
                <tr>
                  <td>Expense</td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                </tr>
                <tr>
                  <td>Bill Payments</td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                  <td><div className="green-badge">Access</div></td>
                  <td><div className="orange-badge">Declined</div></td>
                </tr>
              </tbody>
            </Table>

          </Tab.Pane>
          <Tab.Pane eventKey="admin">
            <AdminList />
          </Tab.Pane>
          <Tab.Pane eventKey="manager">
            <ManagerList />
          </Tab.Pane>
          <Tab.Pane eventKey="finance">
            <FinanceList />
          </Tab.Pane>
          <Tab.Pane eventKey="employers">
            <EmployerList />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default RolesAndResponsibility;