import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import ContactDeleteModal from "./ContactDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { contactsViewStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const ContactViewDetails = () => {
  const [modalShow, deleteModalShow] = React.useState(false);

  const dispatch = useDispatch();
  const contactsView = useSelector((state) => state.accounts.contactsView);
  const params = useParams();
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(contactsViewStart({ m_contact_unique_id: params.id }));
  }, [profile]);

  return (
    <>
      <div className="efi-bank-view-wrapped">
        <div className="efi-bank-view-content-frame">
          <div className="efi-bank-view-content-head">
            <div className="efi-bank-head-info">
              <h3>
                <Link to={-1}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    enableBackground="new 0 0 240.823 240.823"
                    version="1.1"
                    viewBox="0 0 240.823 240.823"
                    xmlSpace="preserve"
                  >
                    <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path>
                  </svg>
                </Link>{" "}
                <span>Contact Detail</span>
              </h3>
            </div>
            <div className="efi-bank-head-status">
              <Button
                className="delete-contact"
                onClick={() => deleteModalShow(contactsView.data?.m_contact)}
                disabled={profile.buttonDisable || contactsView.buttonDisable}
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="efi-bank-view-content-body">
            {contactsView.loading ? (
              <Row className="g-4">
                <Col lg={6}>
                  <Skeleton height={250} width={"100%"} />
                </Col>
                <Col lg={6}>
                  <Skeleton height={250} width={"100%"} />
                </Col>
              </Row>
            ) : Object.keys(contactsView.data).length > 0 &&
              Object.keys(contactsView.data.m_contact).length > 0 ? (
              <React.Fragment>
                <Row className="g-4">
                  <Col lg={6}>
                    <div className="efi-bank-account-details-car">
                      <div className="account-details-card-title">
                        <h4>Contact Details</h4>
                      </div>
                      <div className="grid-layout-two">
                        <div className="grid-layout-card">
                          <h5>Name</h5>
                          <p>{contactsView.data.m_contact.name}</p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Unique ID</h5>
                          <p>{contactsView.data.m_contact.unique_id}</p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Created At</h5>
                          <p>
                            {contactsView.data.m_contact.created_at_formatted}
                          </p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Updated At</h5>
                          <p>
                            {contactsView.data.m_contact.updated_at_formatted}
                          </p>
                        </div>
                      </div>
                      <div className="grid-layoit-two"></div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="efi-bank-account-details-car">
                      <div className="account-details-card-title">
                        <h4>Basic Contact Details</h4>
                      </div>
                      <div className="grid-layout-two">
                        <div className="grid-layout-card">
                          <h5>IBAN</h5>
                          <p>{contactsView.data.m_contact.iban}</p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Intra Bank Account Number</h5>
                          <p>{contactsView.data.m_contact.account_number}</p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Mobile</h5>
                          <p>{contactsView.data.m_contact.mobile}</p>
                        </div>
                        <div className="grid-layout-card">
                          <h5>Email id</h5>
                          <p>{contactsView.data.m_contact.email}</p>
                        </div>
                      </div>
                      <div className="grid-layoit-two"></div>
                    </div>
                  </Col>
                </Row>
                {modalShow && (
                  <ContactDeleteModal
                    show={modalShow}
                    onHide={() => deleteModalShow(false)}
                  />
                )}
              </React.Fragment>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactViewDetails;
